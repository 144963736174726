import React, { useState, useContext, useEffect } from 'react'

//router
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Row, Col, Container, Form, Button, Image } from 'react-bootstrap'
//react-bootstrap
import { Accordion, useAccordionButton, AccordionContext, Nav, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Autoplay]);


function CustomToggle({ children, eventKey, onClick }) {

    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) => onClick({ state: !active, eventKey: eventKey }));

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <Link to="#" aria-expanded={isCurrentEventKey ? 'true' : 'false'} className="nav-link" role="button" onClick={(e) => {
            decoratedOnClick(isCurrentEventKey)
        }}>
            {children}
        </Link>
    );
}
// YourComponent.js
export const sugarEvent = new CustomEvent('fetchSugar');

export const handleClicky = () => {
    // Your click handling logic...

    // Dispatch the custom event to trigger fetching sugar data
    window.dispatchEvent(sugarEvent);
};

const VerticalNav = React.memo(() => {
    const [activeMenu, setActiveMenu] = useState(false)
    const [active, setActive] = useState('')
    const [newsfeedClicks, setNewsfeedClicks] = useState(0);
    const [feedbackClicks, setFeedbackClicks] = useState(0);
    const [suggest, setSuggested] = useState(false);
    const [showStatistics, setShowStatistics] = useState(false);
    const navigate = useNavigate();
    const [sugar, setSugar] = useState('');
    const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token') == null? "": localStorage.getItem('access_token'))
    useEffect(() => {
        var accessTokenTimeout = setTimeout(() => {
          const token = localStorage.getItem("access_token");
          if (token != null) {
            setAccessToken(token);
            clearTimeout(accessTokenTimeout);
          }
        }, 100);
      
       })
      

    useEffect(() => {
        if(accessToken.length == 0)
            return
            
        const fetchSugar = () => {
            fetch('https://scoopt.ngrok.app/sugar', {
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${accessToken}`
                }),
                credentials: 'include'
            }).then((response) => response.json())
                .then((data) => setSugar(data.sugar))
                .catch((error) => console.error('Error fetching sugar:', error));
        };
        // Fetch sugar initially
        fetchSugar();

        // Listen for the custom event and refetch sugar
        window.addEventListener('fetchSugar', fetchSugar);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('fetchSugar', fetchSugar);
        };
    }, [accessToken]);
    let location = useLocation();

    useEffect(() => {
        if (newsfeedClicks >= 5 && feedbackClicks >= 5 && location.pathname === '/dashboard/app/notification') {
            setShowStatistics(true);
        }
    }, [newsfeedClicks, location]);

    function handleSuggestedClick() {
        setSuggested(false)
    }
    function handleNewsfeedClick() {
        setNewsfeedClicks(newsfeedClicks + 1);
    }

    function handleFeedbackClick() {
        setFeedbackClicks(feedbackClicks + 1);
    }
    //location
    // console.log(document);


    return (
        <React.Fragment>
            <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
                <li className="nav-item static-item">
                    <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                        <span className="default-icon">Social</span>
                        <span className="mini-icon" data-bs-toggle="tooltip" title="Social" data-bs-placement="right">-</span>
                    </Link>
                </li>
                {sugar === 'active' && (
                    <li className={`${sugar === 'active' ? 'active' : ''} nav-item `}>
                        <Link className={`${sugar === 'active' ? 'active' : ''} nav-link `} aria-current="page" to="/" onClick={handleNewsfeedClick}>
                            <OverlayTrigger placement="right" overlay={<Tooltip>Newsfeed</Tooltip>}>
                                <i className="icon material-symbols-outlined">
                                    newspaper
                                </i>
                            </OverlayTrigger>
                            <span className="item-name">Newsfeed</span>
                        </Link>
                    </li>
                )}

                <li className={`${location.pathname === '/' && sugar !== 'active' ? 'active' : ''} nav-item `}>
                    <Link className={`${location.pathname === '/' && sugar !== 'active' ? 'active' : ''} nav-link `} aria-current="page" to="/" onClick={handleSuggestedClick}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Suggested</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                trending_up
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Suggested</span>
                    </Link>
                </li>
                

                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/dashboard/app/notification' ? 'active' : ''} nav-link `} aria-current="page" to="/dashboard/app/notification">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Notifications</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                notifications
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Notifications</span>
                    </Link>
                </Nav.Item>
                <br></br>
                <li className="nav-item static-item">
                    <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                        <span className="default-icon">FEATURED</span>
                        <span className="mini-icon" data-bs-toggle="tooltip" title="Social" data-bs-placement="right">-</span>
                    </Link>
                </li>

                {showStatistics && (
                    <Nav.Item as="li">
                        <Link className={`${location.pathname === '/dashboard/app/admin' ? 'active' : ''} nav-link`} to="/dashboard/app/admin">
                            <OverlayTrigger placement="right" overlay={<Tooltip>Statistics</Tooltip>}>
                                <i className="icon material-symbols-outlined">
                                    poll
                                </i>
                            </OverlayTrigger>
                            <span className="item-name">Statistics</span>
                        </Link>
                    </Nav.Item>
                )}
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/dashboard/extrapages/pages-timeline' ? 'active' : ''} nav-link`} to="/dashboard/extrapages/pages-timeline">

                        <OverlayTrigger placement="right" overlay={<Tooltip>History</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                history
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">History</span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/dashboard/app/file' ? 'active' : ''} nav-link `} aria-current="page" to="/dashboard/app/file">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Saved</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                insert_drive_file
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Saved</span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/dashboard/extrapages/pages-pricing' ? 'active' : ''} nav-link `} aria-current="page" to="/dashboard/extrapages/pages-pricing">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Upgrade Plan</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                bolt
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Upgrade Plan</span>
                    </Link>
                </Nav.Item>
                {/* <Nav.Item as="li">
                    <Link className={`${location.pathname === '/dashboard/extrapages/blankpage' ? 'active' : ''} nav-link`} to="/dashboard/extrapages/blankpage" onClick={handleFeedbackClick}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>DocuChat</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                hub
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">DocuChat</span>
                    </Link>
                </Nav.Item> */}
                <br></br>
                <li className="nav-item static-item">
                    <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                        <span className="default-icon">OTHER PAGES</span>
                        <span className="mini-icon">-</span>
                    </Link>
                </li>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/dashboard/Email/email-compose' ? 'active' : ''} nav-link`} to="/dashboard/Email/email-compose" onClick={handleFeedbackClick}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Feedback</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                assignment
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Feedback</span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link className={`${location.pathname === '/dashboard/Email/email' ? 'active' : ''} nav-link`} to="/dashboard/Email/email" onClick={handleFeedbackClick}>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Request Sources</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                unknown_document
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Request Sources</span>
                    </Link>
                </Nav.Item>
                {/* <Nav.Item as="li">
                    <Link className={`${location.pathname === '/extra-pages/pages-comingsoon' ? 'active' : ''} nav-link`} to="/extra-pages/pages-comingsoon">
                        <OverlayTrigger placement="right" overlay={<Tooltip>Comming Soon</Tooltip>}>
                            <i className="icon material-symbols-outlined">
                                fiber_smart_record
                            </i>
                        </OverlayTrigger>
                        <span className="item-name">Release Coming Soon</span>
                    </Link>
                </Nav.Item> */}
            </Accordion>

        </React.Fragment>

    )
})

export default VerticalNav
