import React from 'react'
import {Row,Col,Container,Form,Button,Image} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'

import { useState } from "react";
//swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
// import 'swiper/components/navigation/navigation.scss';

//img
import logo from '../../../assets/images/logo-full.png'
import login1 from '../../../assets/images/login/1.png'
import login2 from '../../../assets/images/login/2.png'
import login3 from '../../../assets/images/login/3.png'

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignUp = () => {
   const navigate = useNavigate();
   const [username, setUsername] = useState("");
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const handleUsernameChange = (event) => {
   setUsername(event.target.value);
   };

   const handleEmailChange = (event) => {
      setEmail(event.target.value);
   };

   const handlePasswordChange = (event) => {
      setPassword(event.target.value);
   };
   const handleSignUp = () => {
      const data = {
         username,
         email,
         password
      };
      fetch("https://scoopt.ngrok.app/register", {
         method: "POST",
         headers: new Headers({
            "ngrok-skip-browser-warning": "69420",
            'Content-Type': 'application/json'
          }),
         body: JSON.stringify(data)
      })
         .then(response => response.json())
         .then(data => {
         console.log(data); // Do something with the response from the API
         navigate("/auth/sign-in");
         
         })
         .catch(error => {
         console.error(error);
         });
   };
   return (
      <>
         <section className="sign-in-page">
            <div id="container-inside">
                  <div id="circle-small"></div>
                  <div id="circle-medium"></div>
                  <div id="circle-large"></div>
                  <div id="circle-xlarge"></div>
                  <div id="circle-xxlarge"></div>
            </div>
            <Container className="p-0">
               <Row className="no-gutters">
                  <Col md="6" className="text-center pt-5">
                  <div className="sign-in-detail text-white">
                        <div className="sign-slider overflow-hidden ">
                        <h2 className='text-white'>Welcome to Scoopt!</h2>
                           <Swiper 
                              spaceBetween={30} 
                              centeredSlides={true} 
                              autoplay={{
                                 "delay": 11000,
                                 "disableOnInteraction": false }} 
                              className="list-inline m-0 p-0 ">
                                 
                              <SwiperSlide>
                                    <br></br>
                                    <p>Your one-stop destination for the latest news and information from around the world. Scoopt is a cutting-edge news aggregation website that leverages advanced algorithms and artificial intelligence technologies to bring you the most relevant and up-to-date content from a diverse array of sources.</p>
                              </SwiperSlide>
                              <SwiperSlide>
                                    <br></br>
                                    <p>With Scoopt, you no longer have to spend hours sifting through countless websites and news sources to stay informed. Our platform aggregates news from trusted sources and curates it into a single, easy-to-use interface, providing you with a personalized and streamlined experience.</p>
                              </SwiperSlide>
                              <SwiperSlide>
                                 <br></br>
                                 <p>But Scoopt is more than just a news aggregator. Our advanced AI algorithms generate concise summaries of articles, making it easy for you to stay informed, even when you're short on time. We also provide the ability to like, comment on, and share articles, as well as choose and add new sources, so you can customize your news experience to fit your needs.



At Scoopt, we believe that staying informed is essential, and our goal is to make that process as simple and effortless as possible. Join us today and discover a new way to stay connected to the world.</p>
                              </SwiperSlide>
                           </Swiper>
                        </div>
                     </div>
                  </Col>
                  <Col md="6" className="bg-white pt-5 pt-5 pb-lg-0 pb-5">
                     <div className="sign-in-from">
                        <h1 className="mb-0">Sign Up</h1>
                        <br></br>
                        <p>Enter your username, email address and password to register.</p>
                        <Form className="mt-4">
                           <Form.Group className="form-group">
                           <Form.Label>Username</Form.Label>
                           <Form.Control
                              type="text"
                              className="mb-0"
                              placeholder="Username"
                              value={username}
                              onChange={handleUsernameChange}
                           />
                           </Form.Group>
                           <Form.Group className="form-group">
                           <Form.Label>Email address</Form.Label>
                           <Form.Control
                              type="email"
                              className="mb-0"
                              id="exampleInputEmail2"
                              placeholder="Email address"
                              value={email}
                              onChange={handleEmailChange}
                           />
                           </Form.Group>
                           <Form.Group className="form-group">
                           <Form.Label>Password</Form.Label>
                           <Form.Control
                              type="password"
                              className="mb-0"
                              id="exampleInputPassword1"
                              placeholder="Password"
                              value={password}
                              onChange={handlePasswordChange}
                           />
                           </Form.Group>
                           <div className="d-inline-block w-100">
                              <Form.Check className="d-inline-block mt-2 pt-1">
                                    <Form.Check.Input type="checkbox" className="me-2" id="customCheck1"/>
                                    <Form.Check.Label>I accept <Link to="#">Terms and Conditions</Link></Form.Check.Label>
                              </Form.Check>
                              <Button type="button" className="btn-primary float-end" onClick={handleSignUp}>Sign Up</Button>
                           </div>
                        </Form>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>  
      </>
   )
}

export default SignUp
