import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Header from '../../../components/partials/dashboard/HeaderStyle/header'

const Timeline = () => {
  const [historyData, setHistoryData] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [expandedBody, setExpandedBody] = useState({});
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const [accessToken, setAccessToken] = useState(!localStorage.getItem("access_token")? "": localStorage.getItem("access_token"))

  useEffect(() => {
    if (localStorage.getItem("access_token") == null) {
      fetch("https://scoopt.ngrok.app/jwt_login", {
      method: "POST",
      credentials: 'include',
      headers: {
        "ngrok-skip-browser-warning": "69420",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "username": "test", "password": "password" }),
    }).then(response => response.json()).then(data => {
      localStorage.setItem("access_token", data)
      setAccessToken(data)
    })
  }
  }, []);

  const handleWindowResize = () => {
    setIsSmallScreen(window.innerWidth < 1000);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const handleSeeLessClick = () => {
    setExpandedBody({});
  }
  const handleSeeMoreClick = (title) => {
    setExpandedBody({ ...expandedBody, [title]: true });
  };
  const onHoverIn = () => {
    setHovered(true);
  };

  const onHoverOut = () => {
    setHovered(false);
  };
  const processData = (data) => {
    return Object.keys(data)
      .map(action => {
        const post = data[action];
        return { ...post, action };
      })
      .sort((a, b) => b.time.localeCompare(a.time));
  };

  useEffect(() => {
    if(accessToken.length == 0)
      return

    fetch('https://scoopt.ngrok.app/history', {
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => setHistoryData(processData(data)))
      .catch(error => console.log(error));
  }, [accessToken]);



  return (
    <>
      <Header />
      <div id='content-page' className='content-page'>
        <Container >
          <Row className='justify-content-center' >
            <Col lg='6' className='w-100'>
              <Card>
                <Card.Header className='d-flex justify-content-between'>
                  <div className='header-title'>
                    <h4 className='card-title'>History</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <ul className='iq-timeline ms-1'>
                    {historyData.map(post => (
                      <li key={post.postId}>
                        <div className='timeline-dots'></div>
                        <div className={`${isSmallScreen ? '' : 'd-flex'}`}>
                          <div className='d-flex'><Link title="You have seen this post!"><i className="icon material-symbols-outlined me-3">
                            {post.action.split(' with')[0] === 'Viewed a Post' ? 'visibility' : 'favorite'}
                          </i></Link> 
                          <h6 className='mb-1'>{post.action.split(' with')[0]} from <Link onClick={() => window.open(post.url)}>{post.name}</Link></h6>
                          </div>
                          <small className='ms-auto'><Link>{post.time.slice(0, -7)}</Link></small>
                        </div>
                        <br></br>
                        <div className={`${isSmallScreen ? '' : 'd-flex'}`}>
                          <img
                            src={post.photo}
                            className={`me-4 ${isSmallScreen ? ' mb-3' : ''}`}
                            style={{ maxWidth: '200px', maxHeight: '200px', filter: "blur(3px)" }}
                          />
                          <div>
                            <div
                              className='d-inline-block w-100'
                              style={{
                                fontSize: '14px',
                                color: hovered ? 'var(--bs-primary)' : 'inherit',
                                cursor: 'pointer',
                              }}
                              // onMouseEnter={onHoverIn}
                              // onMouseLeave={onHoverOut}
                              onClick={() => window.open(post.url)}
                            >
                              <p>{post.title}</p>
                            </div>
                            <div className='d-inline-block w-100' style={{ fontSize: '12px' }}>
                              {expandedBody[post.title] ? <div><div className='mb-3 mt-3'>Summary:</div>{post.summary}<Link className='me-4' onClick={() => handleSeeLessClick()}>
                                {' '}
                                See less...{' '}
                              </Link> </div> : post.summary.substr(0, 200)}
                              {post.summary.length > 200 && !expandedBody[post.title] && (
                                <Link className='me-4' onClick={() => handleSeeMoreClick(post.title)}>
                                  {' '}
                                  See more...{' '}
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                        <hr />
                      </li>

                    ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Timeline;
