import React, { useState, useEffect, useRef } from "react";
import {
  Dropdown,
  Nav,
  Form,
  Card,
  Container,
  Image,
  Modal
} from "react-bootstrap";
import { Link } from "react-router-dom";
import user1 from "../../../../assets/images/user/1.jpg";
//Componets
import { SafeAreaView } from 'react-native-web';
import CustomToggle from "../../../dropdowns";
// import DropdownMenu from "react-bootstrap/esm/DropdownMenu";
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
//react-bootstrap
import { Accordion, useAccordionButton, AccordionContext, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
SwiperCore.use([Navigation, Autoplay]);
const Header = ({ updateData, onCategorySelect }) => {
  const allCategoriesRef = useRef(['World', 'Technology', 'Politics', 'Finance', 'Lifestyle', 'Sports', 'Business' ])
  const [userName, setUserName] = useState("Guest");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedSort, setSelectedSort] = useState('Trending');
  const [currentTime, setCurrentTime] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);
  const [isSidebarMini, setIsSidebarMini] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token') == null? "": localStorage.getItem('access_token'))
  useEffect(() => {
    var accessTokenTimeout = setTimeout(() => {
      const token = localStorage.getItem("access_token");
      if (token != null) {
        setAccessToken(token);
        clearTimeout(accessTokenTimeout);
      }
    }, 100);
  
   })

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handleSignUp = () => {
    const data = {
      username,
      email,
      password
    };
    fetch("https://scoopt.ngrok.app/register", {
      method: "POST",
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data); // Do something with the response from the API;
        window.location.reload();
      })
      .catch(error => {
        console.error(error);
      });
  };
  const handleSignIn = async () => {
    const data = {
      username,
      password
    };

    const response = await fetch('https://scoopt.ngrok.app/login', {
      method: 'POST',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      body: JSON.stringify(data),
      credentials: 'include'
    });

    if (response.ok) {
      // Redirect the user to the home page if the login is successful
      console.log(data)
      window.location.reload();
      localStorage.setItem('username', userName)
      localStorage.setItem('logged', "true")
    } else {
      // Display an error message if the login is unsuccessful
      alert('Invalid username or password');
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRegisterModalOpen = () => {
    setShowRegisterModal(true);
  };

  const handleRegisterModalClose = () => {
    setShowRegisterModal(false);
  };

  const handleModalOpen = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  let location = useLocation();
  const handleClearSearch = () => {
    setSearchQuery('');

    // Send POST request to API endpoint with an empty search query
    fetch('https://scoopt.ngrok.app/search', {
      method: 'POST',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include',
      body: JSON.stringify({ query: '' })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        updateData();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const minisidebar = (setSidebarMini) => {
    const asideElement = document.getElementsByTagName("ASIDE")[0];
    if (setSidebarMini) {
      asideElement.classList.add("sidebar-mini");
    } else {
      asideElement.classList.remove("sidebar-mini");
    }
    setIsSidebarMini(setSidebarMini);
  };

  const toggleSidebar = () => {
    minisidebar(!isSidebarMini);
  };
  const handleWindowResize = () => {
    setIsSmallScreen(window.innerWidth < 800);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    minisidebar(isSmallScreen);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [isSmallScreen]);
  const handleCategoryClick = (category) => {
    if(category.localeCompare(selectedCategory) != 0) {
      setSelectedCategory(category);
      onCategorySelect(category);
    }
    else {
      setSelectedCategory('All');
      onCategorySelect('All');
    }
  };
  const handleSortClick = (category) => {
    setSelectedSort(category);
  };
  useEffect(() => {
    // Set the current time every second
    const intervalId = setInterval(() => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      setCurrentTime(`${year}-${month}-${day} ${hours}:${minutes}:${seconds}`);
    }, 1000);
  });
  useEffect(() => {
    if(accessToken.length == 0)
      return;

    fetch("https://scoopt.ngrok.app/user", {
      method: "GET",
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: "include"
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.user_id) {
          var tmpp = data.user_id.charAt(0).toUpperCase() + data.user_id.slice(1);
          if (tmpp.slice(0, 5) === 'Guest') {
            tmpp = 'Guest';
          }
          setUserName(tmpp);
        } else {
          setUserName("Guest");
        }
      })
      .catch((error) => console.error(error));
  }, [accessToken]);
  const handleSearchSubmit = (event) => {
    event.preventDefault();

    // Send POST request to API endpoint with search query
    fetch('https://scoopt.ngrok.app/search', {
      method: 'POST',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include',
      body: JSON.stringify({ query: searchQuery })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        updateData();
      })
      .catch(error => {
        console.error(error);
      });
  }

  function handleSignOut() {
    fetch('https://scoopt.ngrok.app/logout', {
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include'
    })
      .then(response => {
        var oldTheme = JSON.parse(JSON.stringify(localStorage.getItem('theme')))
        localStorage.clear()
        localStorage.setItem('theme', oldTheme)
        console.log(response);  // handle the response data as needed
        window.location.reload();
      })
      .catch(error => {
        console.error(error);  // handle the error as needed
      });
  }

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  }
  return (
    <>
      <div style={{ zIndex: 1111, position: "sticky", width: '100%', top: 0 }} >
        <Nav
          expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center pb-1 pb-lg-0">
            <Link
                to="#"
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={toggleSidebar}
              >
                <Link>
                <div className="icon material-symbols-outlined iq-burger-menu">
                  menu
                </div></Link>
              </Link>
              <Link
                to="/"
                className="d-flex align-items-center gap-2 iq-header-logo"
              >
                <svg
                  width="40"
                  viewBox="0 0 3340 3560"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2575 3389 c-240 -135 -383 -277 -484 -476 -149 -297 -127 -740 63 -1265 24 -68 48 -135 51 -150 l7 -28 -193 -2 -194 -3 0 -75 0 -75 225 -3 c244 -2 250 -4 250 -59 l0 -23 -155 0 -155 0 0 -80 0 -80 179 0 179 0 7 -86 c23 -267 -82 -520 -282 -686 -52 -43 -53 -43 -54 -103 -1 -33 -2 -73 -3 -88 -3 -38 8 -42 48 -19 151 89 318 291 392 472 103 254 82 540 -68 915 -86 213 -184 535 -203 667 l-7 48 208 2 209 3 3 77 3 78 -223 2 -223 3 1 140 c3 207 49 357 154 497 59 79 192 183 306 241 58 29 64 41 64 135 0 76 -5 77 -105 21z"
                    fill="currentColor"
                  />
                </svg>


                <h3
                  className="logo-title d-none d-sm-block ms-2"
                  data-setting="app_name"
                >
                  Scoopt
                </h3>
              </Link>
              
            </div>

            <div style={{ marginTop: !isSmallScreen ? "1vh" : null, maxWidth: isSmallScreen ? "50%" : null }} className="d-flex iq-search-bar device-search position-relative">
              <form
                action="#"
                className="searchbox"
                onSubmit={handleSearchSubmit}
              >
                <Link className="search-link" to="/">
                  {searchQuery && (
                    <span onClick={handleClearSearch} className="material-symbol-soutlined clear-button">
                      clear
                    </span>
                  )}
                  <span onClick={handleSearchSubmit} className="material-symbols-outlined">search</span>
                </Link>
                <Form.Control
                  type="text"
                  className="text search-input form-control bg-soft-primary"
                  placeholder="Search here..."
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />

              </form>
              <Dropdown>
                <Dropdown.Toggle href="#" variant="d-flex align-items-center" >
                  <div className="caption d-none d-lg-block">
                    <h6 className="mb-0 line-height" style={{ fontSize: '12px' }}>Category: <Link>{selectedCategory}</Link></h6>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Card className="shadow-none m-0">
                    <Card.Header>
                      <h6>Categories</h6>
                    </Card.Header>
                    <Card.Body className="p-0">
                      {
                        allCategoriesRef.current.map(category => 
                          <div
                          className="d-flex align-items-center iq-sub-card border-0"
                          onClick={() => handleCategoryClick(category)}
                        >
                        {selectedCategory == category? <span className="material-symbols-outlined">task_alt</span>: <span className="material-symbols-outlined">radio_button_checked</span>}
                        <div className="ms-3">
                          <Link className="mb-0 h6">{category}</Link>
                        </div>
                      </div>
                        )
                      }
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <ul className="navbar-nav navbar-list" style={{ borderTop: '0' }}>
              {isSmallScreen ? null :
                <Dropdown as="li" className="nav-item ">
                  <Dropdown.Toggle
                    href="#"
                    as={CustomToggle}
                    variant="search-toggle d-flex align-items-center"
                  >
                    <i className="material-symbols-outlined">notifications</i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="sub-drop">
                    <Card className="shadow-none m-0">
                      <Card.Header className="d-flex justify-content-between bg-primary">
                        <div className="header-title bg-primary">
                          <h5 className="mb-0 text-white ">Notifications</h5>
                        </div>
                        <small className="badge  bg-light text-dark">0</small>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <Link to="#" className="iq-sub-card">
                          <div className="d-flex align-items-center">
                            <div className="">
                              <Image
                                className="avatar-40 rounded"
                                src={user1}
                                alt=""
                                loading="lazy"
                              />
                            </div>
                            <div className="ms-3 w-100">
                              <h6 className="mb-2 text-nowrap">No notifications yet.</h6>
                              <div className="d-flex justify-content-between align-items-center">
                                <small className="float-right font-size-12">
                                  {currentTime}
                                </small>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Card.Body>
                    </Card>
                  </Dropdown.Menu>
                </Dropdown>}

              {userName.includes(':') || userName.includes('.') || userName == 'Guest' ? <div className={` ${isSmallScreen ? 'me-1' : 'me-3 ms-3 mt-4'}`}><Link className="bg-primary rounded text-white p-2 me-2" style={{ fontSize: isSmallScreen ? '11px' : '14px' }} onClick={(e) => {
                e.preventDefault();
                handleRegisterModalOpen();
              }}>Join</Link> <Link style={{ fontSize: isSmallScreen ? '11px' : '14px' }} onClick={(e) => {
                e.preventDefault();
                handleModalOpen();
              }}>Log In</Link></div> : (
                <Dropdown as="li" className="nav-item user-dropdown">
                  <Dropdown.Toggle
                    href="#"
                    as={CustomToggle}
                    variant="d-flex align-items-center"
                  >{isSmallScreen ? null : <span className="material-symbols-outlined me-3" style={{ fontSize: "2em" }}>
                    account_circle
                  </span>}

                    <div className="caption">
                      {/* {!isSmallScreen && (
                      <h6 className="mb-0 line-height" style={{ fontWeight: 'bold', letterSpacing: '0.03em', fontSize: isSmallScreen ? '8px' : '16px', marginTop: isSmallScreen ? '2vh' : null, marginLeft: isSmallScreen ? 'auto' : null, marginRight: isSmallScreen ? null : '2vh' }}><Link>{userName.includes(':') || userName.includes('.') ? 'Guest' : userName}</Link></h6>
                    )} */}
                      {isSmallScreen && (
                        <span className="material-symbols-outlined mt-3">
                          account_circle
                        </span>
                      )}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="sub-drop caption-menu">
                    <Card style={{ width: '20vh' }} className="shadow-none m-0">
                      <Card.Header>
                        <div className="header-title">
                          <h5 className="mb-0 ">Hello {userName.includes(':') || userName.includes('.') ? 'Guest' : userName},</h5>

                        </div>
                      </Card.Header>
                      <Card.Body className="p-0 ">
                        <div className="d-flex align-items-center iq-sub-card border-0">
                          <span className="material-symbols-outlined">
                            manage_accounts
                          </span>
                          <div className="ms-3">
                            <Link
                              to="/dashboard/app/user-account-setting"
                              className="mb-0 h6 text-nowrap"
                            >
                              Account settings
                            </Link>
                          </div>
                        </div>
                        <div className="d-flex align-items-center iq-sub-card border-0">
                          <span className="material-symbols-outlined">lock</span>
                          <div className="ms-3">
                            <Link
                              to="/dashboard/app/user-privacy-setting"
                              className="mb-0 h6"
                            >
                              Privacy Settings
                            </Link>
                          </div>
                        </div>
                        
                        <div className="d-flex align-items-center iq-sub-card">
                          <span className="material-symbols-outlined">login</span>
                          <div className="ms-3">
                            <Link to="/" onClick={handleSignOut} className="mb-0 h6">
                              Sign out
                            </Link>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Dropdown.Menu>
                </Dropdown>)}
            </ul>
          </Container>
        </Nav>
        <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" style={showModal ? { display: 'block', pointerEvents: 'none' } : { pointerEvents: 'none' }}>
          <div className="modal-dialog modal-dialog-centered ps-3">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Login</h5>
                <button type="button" className="btn-close" onClick={handleModalClose}></button>
              </div>
              <div className="modal-body">
                <Form className="mt-2">
                  <Form.Group className="form-group">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="email"
                      className="mb-0"
                      placeholder="Username"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Password</Form.Label>

                    <Form.Control
                      type="password"
                      className="mb-0"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </Form.Group>
                  <Link to="#" style={{ fontSize: '12px' }}>
                    Forgot password?
                  </Link>
                  <div className="d-inline-block w-100">
                    <Form.Check className="d-inline-block mt-2 pt-1">
                      <Form.Check.Input
                        type="checkbox"
                        className="me-2"
                        id="customCheck11"
                      />
                      <Form.Check.Label><div className='mt-1 ms-1' style={{ fontSize: '12px' }}>Stay signed in</div></Form.Check.Label>{' '}
                    </Form.Check>
                    <br></br>
                    <Button
                      variant="primary"
                      type="button"
                      className='float-end mt-0 mb-0'
                      onClick={handleSignIn}
                    >
                      Sign in
                    </Button>
                  </div>
                  <hr />
                  <span style={{ fontSize: '12px' }}>Don't have an account? <Link onClick={(e) => {
                    e.preventDefault();
                    handleRegisterModalOpen();
                  }}>Sign up</Link></span>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} style={showModal ? { display: 'block', pointerEvents: 'none' } : { pointerEvents: 'none' }}></div>
        <div
          className={`modal ${showRegisterModal ? 'show' : ''}`}
          tabIndex="-1"
          style={
            showRegisterModal
              ? { display: 'block', pointerEvents: 'none' }
              : { pointerEvents: 'none' }
          }
        >
          <div className="modal-dialog modal-dialog-centered ps-3">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Register</h5>
                <button type="button" className="btn-close" onClick={handleRegisterModalClose}></button>
              </div>
              <div className="modal-body">
                <Form className="mt-2">
                  <Form.Group className="form-group">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      className="mb-0"
                      placeholder="Username"
                      value={username}
                      onChange={handleUsernameChange}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      className="mb-0"
                      id="exampleInputEmail2"
                      placeholder="Email address"
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      className="mb-0"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </Form.Group>
                  <div className="d-inline-block w-100">
                    <Form.Check className="d-inline-block mt-2 pt-1">
                      <Form.Check.Input type="checkbox" className="me-2" id="customCheck1" />
                      <Form.Check.Label><div className='mt-1' style={{ fontSize: '12px' }}>I accept <Link to="/dashboard/extrapages/terms-of-service">Terms and Conditions</Link></div></Form.Check.Label>
                    </Form.Check>
                    <Button type="button" className="btn-primary float-end mt-4" onClick={handleSignUp}>Sign Up</Button>
                  </div>
                  <hr />
                  <span style={{ fontSize: '12px' }}>Already have an account? <Link onClick={(e) => {
                    e.preventDefault();
                    handleModalOpen();
                  }}>Sign in</Link></span>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop fade ${showRegisterModal ? 'show' : ''}`}
          style={
            showRegisterModal
              ? { display: 'block', pointerEvents: 'none' }
              : { pointerEvents: 'none' }
          }
        ></div>
      </div>
    </>
  );
};

export default Header;
