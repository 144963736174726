import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import Header from '../../../components/partials/dashboard/HeaderStyle/header'


//css
import './email-compose.css'


const EmailCompose = () => {
    const [subject, setSubject] = useState('');
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);
    
    const handleSend = async () => {
        const response = await fetch('https://scoopt.ngrok.app/request', {
            method: 'POST',
            credentials: 'include',
            headers: new Headers({
                "ngrok-skip-browser-warning": "69420",
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({ subject })
        });

        if (!response.ok) {
            throw new Error(`Failed to submit source request: ${response.status}`);
        }

        setShowThankYouMessage(true);
    };
    return (
        <>
            <Header />
            <div style={{ marginTop: '5vh' }}>
                <div id='content-page' className='content-page'>
                    <Container className='relative'>
                        <Row className="row-eq-height">
                            <Col md="12">
                                <Row>
                                    <Col md="12">
                                        <Card className="iq-border-radius-20">
                                            <Card.Body>
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                        <h5 className="text-primary card-title">Request any news source or social media!</h5>
                                                    </Col>
                                                </Row>
                                                <Form className="email-form mt-4">
                                                    <Row className="form-group">
                                                        <Form.Label column sm="2" htmlFor="subject" className="col-form-label mb-4">Source Name or Link:</Form.Label>
                                                        <Col sm="10">
                                                            <Form.Control type="text" id="subject" placeholder="i.e. BBC or bbc.com" value={subject} onChange={(event) => setSubject(event.target.value)} />
                                                        </Col>
                                                    </Row>
                                                    <Form.Group className="form-group d-flex justify-content-between align-items-center compose-bottom pt-3 m-0">
                                                        <div className="d-flex flex-wrap flex-grow-1 align-items-center">
                                                            <div className="send-btn pl-3 mb-2">
                                                                <button type="button" className="btn btn-primary mt-3" onClick={handleSend}>Request</button>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </Form>
                                                {showThankYouMessage && (
                                                    <div className="alert alert-success mt-3" role="alert">
                                                        The source will be added within the next 30 days!
                                                    </div>
                                                )}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default EmailCompose