import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Container, Dropdown, Card } from 'react-bootstrap';
import icon1 from '../../assets/images/icon/01.png'
import icon2 from '../../assets/images/icon/02.png'
import user2 from '../../assets/images/user/01.png'
import { Link } from 'react-router-dom'
import ShareOffcanvas from '../../components/share-offcanvas'
import RightSidebar from '../../components/partials/dashboard/SidebarStyle/rightsidebar'
import Header from '../../components/partials/dashboard/HeaderStyle/header'
import audioFile from '../../assets/images/user/output.wav'
import MobileStoreButton from 'react-mobile-store-button';
const Index = () => {
  const [selectedCategory, setSelectedCategory] = useState('All')
  const initialDataRef = useRef([]);
  const [data, setData] = useState({});
  const [update, setUpdate] = useState(false);
  const [currentArticleId, setCurrentArticleId] = useState(null);
  const [showFullArticle, setShowFullArticle] = useState(false);
  const [likess, setLikes] = useState({});
  const [activeDiv, setActiveDiv] = useState(null);
  const [comments, setComments] = useState([]);
  const [latestNews, setLatestNews] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingA, setLoadingA] = useState(false);
  const [podcasts, setPodcasts] = useState({});
  const [currentEmbed, setCurrentEmbed] = useState(null);
  const MAX_BODY_LENGTH = 150;
  const [expandedBody, setExpandedBody] = useState({});
  const [isSummarySelected, setIsSummarySelected] = useState(false);
  const [colStates, setColStates] = useState({});
  const [likes, setLikesCount] = useState(0);
  const [views, setSeenCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [isSeen, setIsSeen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const [fetching, setFetching] = useState(false);
  const [fl, setFl] = useState(0);
  const [prevFls, setPrevFls] = useState(0);
  const [audioVisible, setAudioVisible] = useState(false);
  const [f2, setf2] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [selectedSort, setSelectedSort] = useState('Trending');
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [accessToken, setAccessToken] = useState(!localStorage.getItem("access_token") ? "" : localStorage.getItem("access_token"))
  const messageInputRef = useRef();
  const [isClickedInfo, setIsClickedInfo] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [audio, setAudio] = useState(null);
  const [recordingIndex, setRecordingIndex] = useState(0);
  const [blobUrl, setBlobUrl] = useState(null);
  const [betaClicks, setBetaClicks] = useState(0);
  const [turboClicks, setTurboClicks] = useState(0);
  const APKUrl =
  "https://play.google.com/store/apps/details?id=host.exp.exponent";
  const iOSUrl = "https://apps.apple.com/app/scoopt/id6447619313";
  const handleClickBeta = () => {
    setBetaClicks(betaClicks + 1);
    if (betaClicks + 1 === 3 && turboClicks === 1) {
      retrain(6);
    }
  };

  const handleClickTurbo = () => {
    setTurboClicks(turboClicks + 1);
    if (betaClicks === 3 && turboClicks + 1 === 1) {
      retrain(5);
    }
  };
  useEffect(() => {
    fetchNextRecording(recordingIndex);
  }, [audio]);

  async function fetchNextRecording(index) {
    try {
      const response = await fetch(`https://scoopt.ngrok.app/next_recording/${index}`, {
        headers: new Headers({
          "ngrok-skip-browser-warning": "69420",
          'Authorization': `Bearer ${accessToken}`
        }),
        credentials: 'include'
      });
      const data = await response.json();
      const audioUrl = `https://scoopt.ngrok.app/audio/${data.file_url}`;
      const audioResponse = await fetch(audioUrl, {
        headers: new Headers({
          "ngrok-skip-browser-warning": "69420",
          'Authorization': `Bearer ${accessToken}`
        }),
        credentials: 'include'
      });

      // Create a Blob from the response data
      const audioData = await audioResponse.arrayBuffer();
      const audioBlob = new Blob([audioData], { type: 'audio/mpeg' });
      const blobUrl = URL.createObjectURL(audioBlob);

      // Set the Blob URL as the audio source
      setBlobUrl(blobUrl);
      setAudio(audioUrl);

    } catch (error) {
      console.error("Error fetching the next recording:", error);
    }
  }

  function handleEnded() {
    setRecordingIndex((prevIndex) => prevIndex + 1);
    fetchNextRecording(recordingIndex + 1).then((audioUrl) => {
      setAudio(audioUrl);
    });
  }
  const handleClickedInfo = () => {
    setIsClickedInfo(!isClickedInfo);
    setShowModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (localStorage.getItem("access_token") == null) {
      fetch("https://scoopt.ngrok.app/jwt_login", {
        method: "POST",
        credentials: 'include',
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "username": "test", "password": "password" }),
      }).then(response => response.json()).then(data => {
        localStorage.setItem("access_token", data)
        setAccessToken(data)
      })
    }
  }, []);

  const sendMessage = useCallback(message => {


    setLoadingA(true);
    fetch('https://scoopt.ngrok.app/ask', {
      method: 'POST',
      credentials: 'include',
      headers: {
        "ngrok-skip-browser-warning": "69420", 'Content-Type': 'application/json', 'Authorization': `Bearer ${accessToken}`
      },
      body: JSON.stringify({ message })
    })
      .then(response => response.json())
      .then(data => {
        setMessages([...messages, { message, type: 'sent' }, { message: data.message, type: 'received' }]);
        setLoadingA(false);
      })
      .catch(error => {
        console.error(error);
        setLoadingA(false);
      });
  }, [messages]);

  const handleTaskClick = () => {
    setAudioVisible(!audioVisible);
  };
  const handleSortClick = (category) => {
    setSelectedSort(category);
  };
  const onHoverIn = () => {
    setHovered(true);
  };

  const onHoverOut = () => {
    setHovered(false);
  };
  const handleWindowResize = () => {
    setIsSmallScreen(window.innerWidth < 1000);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const handleLikeClick = useCallback((idf, pri) => {
    setLikes((prevState) => ({
      ...prevState,
      [idf]: !prevState[idf],
    }));
    fetch(`https://scoopt.ngrok.app/like?idf=${idf}`, {
      method: 'POST',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to like item ${idf}`);
        }
        setLikesCount((pri) => pri + (isLiked ? -1 : 1));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [likes]);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    if (category === 'All') {
      setData(initialDataRef.current); // show all posts
    } else {
      const filtered = initialDataRef.current.filter(post => post.Categories.includes(category));
      setData(filtered);
    }
  }
  const downloadPdf = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://scoopt.ngrok.app/download_pdf', {
        method: 'GET',
        headers: new Headers({
          "ngrok-skip-browser-warning": "69420",
          'Authorization': `Bearer ${accessToken}`
        }),
        credentials: 'include'
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'output.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error downloading PDF:', error);
    }
  };
  const handleColClick = useCallback((idf) => {
    setColStates({ ...colStates, [idf]: true });
    fetch(`https://scoopt.ngrok.app/seen?idf=${idf}`, {
      method: 'POST',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include'
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Failed to like item ${idf}`);
        }
        setSeenCount((pri) => pri + (isSeen ? -1 : 1));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [views]);

  const handleReset = () => {
    setShowFullArticle(false);
    setCurrentArticleId(null);
  };
  const removeDuplicatesByTitle = (arr) => {
    const uniqueTitles = arr.reduce((accumulator, current) => {
      if (!accumulator.some(item => item[1].Title === current[1].Title)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    return uniqueTitles;
  };

  const handleSeeLessClick = () => {
    setExpandedBody({});
  }
  const handleSeeMoreClick = (title) => {
    setExpandedBody({ ...expandedBody, [title]: true });
  };
  const handleAskChanged = (event) => {
    setInputValue(event.target.value);
  };
  const handleToggleIframe = (embed) => {
    setCurrentEmbed(embed === currentEmbed ? null : embed);
  }
  // useEffect(() => {
  //   fetch('https://scoopt.ngrok.app/podcasts', {
  //     credentials: 'include'
  //   })
  //     .then(response => response.json())
  //     .then(data => 
  //       setPodcasts(data));
  // }, []);
  const podcastList = Object.values(podcasts);
  function displayArticleWithReadingTime(Article) {
    // Define a constant for the average reading speed in words per minute
    const READING_SPEED = 240;

    // Calculate the estimated reading time in minutes
    const words = Article.trim().split(/\s+/).length;
    const readingTime = Math.ceil(words / READING_SPEED);

    // Render the HTML code for the article, along with the estimated reading time
    return (readingTime
    );
  }

  useEffect(() => {
    if (localStorage.getItem("access_token") == null) {
      return;
    }

    fetch('https://scoopt.ngrok.app/latest', {
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include'
    })
      .then((response) => {
        if (response.status === 401) { // Unauthorized
          localStorage.clear(); // Clear local storage
          window.location.reload(); // Refresh the page
        }
        return response.json();
      })
      .then(data => setLatestNews(data));
  }, [accessToken]);


  const handleAskChange = () => {
    sendMessage(inputValue);
    setInputValue('');
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAskChange();
    }
  }
  const currentTime = new Date();
  const sortedNewsArray = Object.entries(latestNews)
    .filter(([_, [__, ___, ____, _____, time]]) => {
      const entryTime = new Date(time);
      return entryTime <= currentTime;
    })
    .sort((a, b) => {
      const timeA = new Date(a[1][4]);
      const timeB = new Date(b[1][4]);
      return timeB - timeA;
    });

  const sortedNewsArrayByCategory = sortedNewsArray.filter(([title, [idd, img, siteImg, url, time, name, Categories, body, likes, views]]) => {
    if (selectedCategory == 'All')
      return true;
    return Categories.includes(selectedCategory)
  }
  )

  const newsElements = sortedNewsArrayByCategory.map(([title, [idd, img, siteImg, url, time, name, Categories, body, likes, views]]) => (
    <React.Fragment key={title}>
      <Col sm={12} onClick={() => handleColClick(idd)}>
        <Card className="card-block card-stretch card-height ">
          <Card.Body>
            <div className="d-flex justify-content-between mb-3">
              <div className="me-3">
                <img className="rounded-circle img-fluid" src={siteImg} alt="" />
              </div>
              <div className="w-100">
                <div className="d-flex justify-content-between">
                  <div>
                    <h5 className="mb-0 d-inline-block">{name}</h5>
                    <p className="mb-0 text-primary ">{time.slice(0, -3)}</p>
                  </div>
                  <div className='ms-auto mt-2 '>
                    {colStates[idd] ?
                      <div><Link title="You have seen this post!" className='d-flex'><i className="icon material-symbols-outlined">
                        visibility
                      </i><div className='ms-1'>{colStates[idd] ? parseInt(views, 10) + 1 : views}</div></Link> </div> :
                      <Link title="You have not seen this post!"><i className="icon material-symbols-outlined">
                        visibility_off
                      </i></Link>
                    }
                  </div>
                  <div className="card-post-toolbar">
                    <Dropdown>
                      <Dropdown.Toggle variant="bg-transparent">
                        <span className="material-symbols-outlined">
                          more_horiz
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu m-0 p-0">
                        <Dropdown.Item className=" p-3" to="#">
                          <div className="d-flex align-items-top">
                            <div className="h4 material-symbols-outlined">
                              <i className="ri-save-line"></i>
                            </div>
                            <div className="data ms-2" onClick={() => savePost(title, img, url, name, body)}>
                              <h6>Save Post</h6>
                              <p className="mb-0">Add this to your saved items</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item className="p-3" to="#" onClick={() => handleHidePost(idd)}>
                          <div className="d-flex align-items-top">
                            <i className="ri-close-circle-line h4"></i>
                            <div className="data ms-2">
                              <h6>Hide Post</h6>
                              <p className="mb-0">See fewer posts like this.</p>
                            </div>
                          </div>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <div className={`align-items-center mb-2 ${isSmallScreen ? '' : 'd-flex'}`} >
              <img src={img} alt="post3" className="rounded me-4" style={{ objectFit: "cover", width: isSmallScreen ? "300px" : "370px", height: isSmallScreen ? "200px" : "210px", marginBottom: isSmallScreen ? "2vh" : null }} />
              <div className="d-flex flex-column w-100">
                <div className={`justify-content-between ${isSmallScreen ? 'mt-3 mb-1' : 'd-flex'}`} >
                  <div className='d-inline-block w-100' style={{ fontSize: '14px', color: hovered ? 'var(--bs-primary)' : 'inherit', cursor: 'pointer', }}
                    onMouseEnter={onHoverIn}
                    onMouseLeave={onHoverOut} >
                    <h4 className='mb-3'>{title}</h4>
                  </div>
                </div>
                <p>
                  {expandedBody[title] ? <div><div className='mb-3 mt-3'>Summary </div>{body}<Link className='me-4' onClick={() => handleSeeLessClick()}>
                    {' '}
                    Close Summary...{' '}
                  </Link> </div> : body.substr(0, MAX_BODY_LENGTH)}
                  {body.length > MAX_BODY_LENGTH && !expandedBody[title] && (
                    <Link className='me-4 ms-auto' onClick={() => handleSeeMoreClick(title)}>
                      {' '}
                      ...see more{' '}
                    </Link>
                  )}

                </p><div className="d-flex"><div className='me-1'>Reading time: </div> <Link onClick={() => handleSeeMoreClick(title)}> ~ {displayArticleWithReadingTime(body)} minute</Link></div>
                {Categories && Categories.length > 0 && (
                  <div>
                    Categories:&nbsp;
                    {Categories.filter(category => category !== "Entertainment").map((category, index) => (
                      <React.Fragment key={index}>
                        <Link>{category}</Link>
                        {index !== Categories.filter(category => category !== "Entertainment").length - 1 && ", "}
                      </React.Fragment>
                    ))}
                    {Categories.filter(category => category !== "Entertainment").length > 0 && Categories.filter(category => category !== "Entertainment")[Categories.filter(category => category !== "Entertainment").length - 1].endsWith(",") ? Categories.filter(category => category !== "Entertainment")[Categories.filter(category => category !== "Entertainment").length - 1].slice(0, -1) : null}
                  </div>
                )}
                <br></br>
                <div className='d-flex'>
                  <div className="d-flex  flex-wrap">
                    <div className="like-block position-relative d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <div key={idd}>
                          <div className="d-flex like-data">
                            <img
                              src={likess[idd] ? icon2 : icon1}
                              className="img-fluid"
                              alt=""
                              onClick={() => handleLikeClick(idd, likes)}
                            />
                            <div className='ms-2'>
                              {likess[idd] ? parseInt(likes, 10) + 1 : likes}</div>
                          </div>
                        </div>
                      </div>
                      <div className={`total-comment-block ${isSmallScreen ? 'ms-3' : 'ms-4'}`} onClick={() => handleClick(idd)} style={{ fontWeight: activeDiv === idd ? 'bold' : 'normal' }}>
                        <div className='d-flex'><span className="material-symbols-outlined" style={{ marginTop: '2px' }}>speaker_notes</span><div className='ms-2'>{comments.length}</div></div>
                      </div>
                    </div>
                    <Link to="#" className="d-flex"><span onClick={() => window.open(url)} class=" ms-4 me-2 material-symbols-outlined">
                      open_in_new
                    </span>
                      <div className="total-like-block" style={{ fontSize: isSmallScreen ? '12px' : '14px' }} onClick={() => window.open(url)}>
                        Open on {name}
                      </div>
                    </Link>

                  </div><div className='ms-auto'><ShareOffcanvas idf={idd} Url={url} /></div></div>
              </div>
            </div>
            <div className="comment-area">

              {activeDiv === idd && (
                <ul className="post-comments list-inline p-0 m-0">
                  {/* Check if comments is defined and has a length greater than 0 */}
                  {comments && comments.length > 0 ? (
                    comments.map((comment) => (
                      <div>
                        <hr />
                        <li className="mb-2">
                          <div className="d-flex">
                            <div className="user-img">
                              <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                            </div>
                            <div className="comment-data-block ms-3">
                              <h6>
                                {comment.username && comment.username.includes(':') || comment.username.includes('.') ? <div><Link>Guest</Link></div> : comment.username}                              </h6>
                              <p className="mb-0">{comment.comment}</p>
                              <div className="d-flex flex-wrap align-items-center comment-activity">
                                <Link to="#">like</Link>
                                <Link to="#">reply</Link>
                                <Link to="#">translate</Link>
                                <span>{comment.time && comment.time.slice(0, -7)}</span>
                              </div>
                            </div>
                          </div>
                        </li></div>

                    ))
                  ) : (
                    <div>
                      <hr />
                      <li className="mb-2">No comments yet.</li></div>
                  )}
                  <input
                    type="text"
                    className="form-control rounded"
                    placeholder="Enter Your Comment"
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        addComment(event.target.value, idd);
                        event.target.value = "";
                        // TODO: clear input but don't use refs or static ids(try to use event.target)
                        // sendComment(event.target.value, id); Call the sendComment function with the comment, userId, and postId

                      }
                    }}
                  />
                </ul>

              )}


            </div>
          </Card.Body>
        </Card>
      </Col>
    </React.Fragment>
  ));

  const addComment = (comment, id) => {
    sendComment(comment, id, setComments);
  };


  const handleHidePost = (id) => {
    setData((prevState) => {
      const updatedData = { ...prevState };
      delete updatedData[id];
      return updatedData;
    });
  }
  const handleClick = (id) => {
    if (activeDiv === id) {
      setActiveDiv(null);
    } else {
      setActiveDiv(id);
      fetchComments(id); // Call the fetchComments function with the post id
    }
  };
  useEffect(() => {
    setUpdate(false);
  }, [data]);


  var flag = 0
  const updateData = () => {
    setUpdate(!update);
  };


  var temp = ''

  const sendComment = async (comment, postId, setComments) => {
    const response = await fetch('https://scoopt.ngrok.app/post_comment', {
      method: 'POST',
      body: JSON.stringify({ comment, postId }),
      credentials: 'include',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      })
    });

    if (!response.ok) {
      throw new Error(`Failed to send comment: ${response.status}`);
    }

    const comment_dict = await response.json();

    setComments((comments) => [...comments, comment_dict]);
  };
  const fetchComments = async (postId) => {
    const response = await fetch(`https://scoopt.ngrok.app/comments?postId=${postId}`, {
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch comments: ${response.status}`);
    }

    const datar = await response.json();

    // Convert the object of comments into an array
    const commentsArray = Object.values(datar);

    setComments(commentsArray);
  };
  const retrain = async (num) => {
    setLoadingA(true);
    const response = await fetch('https://scoopt.ngrok.app/retrain', {
      method: 'POST',
      body: JSON.stringify({ num }),
      credentials: 'include',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      })
    });

    if (!response.ok) {
      throw new Error(`Failed to send comment: ${response.status}`);
    }
    setLoadingA(false);
  };

  const fetchMoreData = () => {
    if (prevFls === fl) {
      setFl(fl + 30);
      return; // Prevent making the API call
    }
    setPrevFls(fl);
    if (f2.includes(fl)) {
      setFl(fl + 30);
      return; // Prevent making the API call
    }
    setf2(prevFls => [...prevFls, fl]);
    fetch(`https://scoopt.ngrok.app/data?offset=${fl}`, {
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include'
    })
      .then(response => response.json())
      .then(newData => {
        if (typeof data[Symbol.iterator] === 'function') {
          initialDataRef.current = [...initialDataRef.current, ...newData];
          setData(data => {
            return [...data, ...newData]; // TODO dont just default it when you get new data, apply categories to it
          });
        } else {
          setData([...newData]);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setFetching(false);
      });
  };

  const savePost = (title, articleImg, url, site, Summary) => {
    const requestOptions = {
      method: 'POST',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      body: JSON.stringify({ title, articleImg, url, site, Summary }),
      credentials: 'include'
    };
    fetch('https://scoopt.ngrok.app/save_post', requestOptions)
      .then(response => response.json())
      .then(data => console.log(data))
      .catch(error => console.error(error));
  }

  useEffect(() => {
    if (localStorage.getItem("access_token") == null) {
      return
    }
    setLoading(true);
    window.scrollTo(0, 0);
    fetch('https://scoopt.ngrok.app/data', {
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include'
    })
      .then(response => response.json())
      .then(initialData => {
        initialDataRef.current = initialData;
        setData(initialData);
        temp = initialData
        flag = 0
        setPrevFls(0);
        setf2([]);
        setFl(0);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        // Set loading to false
        setLoading(false);
      });

  }, [update]);
  useEffect(() => {
    let debounceTimeout = null;

    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      if (Object.keys(data).length === 0) {
        return; // Do not execute the scroll event handler if data is empty
      }
      // Clear any existing debounce timeout
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      // Set a new debounce timeout
      debounceTimeout = setTimeout(() => {
        // Check if the user has scrolled past 66% of the page height
        if (scrollPosition >= documentHeight * 0.5) {
          fetchMoreData();
        }
      }, 100); // Set the debounce delay to 300 milliseconds (you can adjust this value)
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [fetchMoreData]);
  useEffect(() => {
    const audioElement = document.getElementById('audio-player');
    if (audioElement) {
      audioElement.addEventListener('ended', handleEnded);
      audioElement.play();
    }
    return () => {
      if (audioElement) {
        audioElement.removeEventListener('ended', handleEnded);
        audioElement.pause();
      }
    };
  }, [audio]);

  const [leftSideOpen, setLeftSideOpen] = useState(true)
  const [rightSideOpen, setRightSideOpen] = useState(true)

  {
    /*
      IF I AM ON DESKTOP, these both should be true initially.
      IF i AM ON MOBILE, and these are both true, then i should set the right side open and left closed
      IF I CLICK TO OPEN LEFT SIDE, i should set left to true and right to false
      IF I CLICK TO OPEN RIGHT SIDE, i should set right to true and left to false
      NO ACTION TO BE TAKEN WHEN THEY ARE BEING CLOSED
    */
  }
  return (
    <div>
      {/* <SocketComponent /> */}
      {/* {isScrollingDown ? null : <Header updateData={updateData} />} */}
      <Header updateData={updateData} onCategorySelect={handleCategorySelect} />

      <RightSidebar setUpdate={setUpdate} />

      <div id="content-page" className="content-page" >

        <Container>
          <Row>

            <Col lg={8} className="row w-100 m-0 p-0">
              {/* {!Object.keys(data).length ? (
          <Col sm={12} className="row w-100 m-0 p-0">
            <Card className="card-block card-stretch card-height">
                <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">Trending Podcasts</h4>
                    </div>
                    {/* <p className='ms-3 mb-0' style={{fontStyle: 'italic', fontSize: '10px'}}>Click on the image to watch the Podcast on Scoopt.</p> */}

              {/* </div>
                <Card.Body>
                  <ul className="d-flex media-story list-inline m-0 p-0" style={{overflowX: 'scroll'}}>
                    {podcastList.map((podcast, index) => (
                      <li key={index} className="d-flex mb-3 ms-3 align-items-center active">
                        <a href="#" className="rounded-circle img-fluid" onClick={() => {
                            handleToggleIframe(podcast.embed)
                          }}>
                          <img style={{ objectFit: "cover", width: "180px", height: "100px" }} src={podcast.img} alt="story-img" />
                        </a>
                        <div className="stories-data ms-3" onClick={() => {
                            handleToggleIframe(podcast.embed)
                          }}>
                          <h6 style={{fontWeight: 600, whiteSpace: 'nowrap'}} className="me-3">{podcast.title}</h6>
                          <p className="mb-0">{podcast.names}</p>
                          <p className="mb-0" style={{fontSize: '12px'}}>{podcast.date}</p>
                          <Link className='me-1' onClick={() => {
                            handleToggleIframe(podcast.embed)
                          }}>Watch </Link>
                          |
                          <Link className='ms-1' onClick={() => window.open(podcast.link)}> Open on YouTube</Link>
                        </div>
                      </li>
                    ))}
                  </ul>
                  {/* <Link to="#" className="btn btn-primary d-block mt-3">See All</Link> */}
              {/* </Card.Body>
            </Card>
            </Col>
            ) : null} */}
              {/* {!Object.keys(data).length ? (
              <Col sm={12} key={2}>
                {currentEmbed &&
                  <Card className="card-block card-stretch card-height">
                    <Card.Body>
                      <div id="myIframe" className="mb-4 ratio ratio-16x9" style={{display: currentEmbed ? 'block' : 'none'}}>
                        <iframe title="video" src={currentEmbed}></iframe>
                      </div>
                    </Card.Body>
                  </Card>
                }
              </Col>
            ) : null}  */}
              {/* <div>
                <MobileStoreButton
                  store="ios"
                  url={iOSUrl}
                  linkProps={{ title: 'iOS Store Button' }}
                />
              </div> */}
              {!Object.keys(data).length ? (
                <React.Fragment>
                  <div className={`d-flex card-header p-1 m-0 d-flex justify-content-between ${isSmallScreen ? 'pb-3' : 'pb-2'}`} >
                    <div className="header-title p-2 ">
                      {/* <div className="header-title bg-primary rounded p-2 "> */}
                      <div className='d-flex'>
                        <h4 className='me-3'>Suggested News</h4><Link><span className="material-symbols-outlined" onClick={handleTaskClick}>headphones</span></Link><Link className=''><span className="material-symbols-outlined ms-2" onClick={downloadPdf}>downloading</span></Link>{loading && (
                          
                        <div className="spinner-border ms-4" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        )}</div></div>
                    {!isSmallScreen &&
                      <Dropdown>
                        <Dropdown.Toggle href="#" variant="d-flex align-items-center" >
                          <div className="caption d-none d-lg-block">
                            <h6 className="mb-0 line-height" style={{ fontSize: '12px' }}>Sort by: <Link>{selectedSort}</Link></h6>
                          </div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Card className="shadow-none m-0">
                            <Card.Header>
                              <h6>Filters</h6>
                            </Card.Header>
                            <Card.Body className="p-0">
                              <div
                                className="d-flex align-items-center iq-sub-card border-0"
                                onClick={() => handleSortClick('Trending')}
                              >
                                <span className="material-symbols-outlined">task_alt</span>
                                <div className="ms-3">
                                  <Link className="mb-0 h6">Trending</Link>
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center iq-sub-card border-0"
                                onClick={() => handleSortClick('Most Liked')}
                              >
                                <span className="material-symbols-outlined">task_alt</span>
                                <div className="ms-3">
                                  <Link className="mb-0 h6">Most Liked</Link>
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center iq-sub-card border-0"
                                onClick={() => handleSortClick('Most Viewed')}
                              >
                                <span className="material-symbols-outlined">task_alt</span>
                                <div className="ms-3">
                                  <Link className="mb-0 h6">Most Viewed</Link>
                                </div>
                              </div>

                            </Card.Body>
                          </Card>
                        </Dropdown.Menu>
                      </Dropdown>}
                  </div>
                  {/* {audioVisible && (
                    <div className='mb-3'>
                      <audio controls>
                        <source src={audioFile} type="audio/mpeg" />
                      </audio></div>)} */}
                  {audioVisible && audio && (
                    <div className='mb-3'>
                      <audio id="audio-player"
                        controls
                        onEnded={handleEnded}
                        onError={(e) => console.error("Error playing the audio:", e)}><source src={blobUrl} type="audio/mpeg" />
                      </audio><div className='ms-1' style={{ fontSize: '11px', fontStyle: 'italic' }}>The next track will play automatically after this one finishes.</div></div>
                  )}
                  {/* <Col sm={12} key={999}>
                    <Card className="card-block card-stretch card-height ">
                      <Card.Body>
                        <div className='d-flex'> <Link><span style={{ fontSize: '18px' }} title="Click me to read more information about ScooptGPT!" class="material-symbols-outlined me-2" onClick={handleClickedInfo}>info</span></Link>
                          <div>ScooptGPT <Link style={{ fontSize: '12px' }}>chat</Link> </div></div>
                        {isClickedInfo && (
                          <><div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} style={showModal ? { display: 'block', pointerEvents: 'none' } : { pointerEvents: 'none' }}></div>
                            <div
                              className={`modal ${showModal ? 'show' : ''}`}
                              tabIndex="-1"
                              style={
                                showModal
                                  ? { display: 'block', pointerEvents: 'none' }
                                  : { pointerEvents: 'none' }}>
                              <div className="modal-dialog modal-dialog-centered ps-3">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5 className="modal-title">ScooptGPT <Link style={{ fontSize: '12px' }}>beta</Link> </h5>
                                    <button
                                      type="button"
                                      className="btn-close"
                                      onClick={handleModalClose}
                                    ></button>
                                  </div>
                                  <div className="modal-body">
                                    Introducing ScooptGPT <Link onClick={handleClickBeta} style={{ fontSize: '12px' }}>beta</Link>, a refined model evolved from GPT-3.5-turbo, specifically trained on the past three days of news content.<br></br><br></br>

                                    ScooptGPT <Link style={{ fontSize: '12px' }}>beta</Link> is designed to address a wide range of inquiries; however, it will respond with 'I don't know' for questions outside the scope of news-related topics.<br></br><br></br>

                                    For subscribed users, Scoopt presents ScooptGPT <Link style={{ fontSize: '12px' }} onClick={handleClickTurbo}>turbo</Link>, an advanced model based on the cutting-edge GPT-4 architecture which has been trained on a wide variety of news.<br></br><br></br>

                                    Example prompts: <br></br><Link> 'Summarise the latest news in 100 words'</Link> <br></br><Link>'Ukraine war latest news'</Link> <br></br><Link>'Incoming recession news'</Link>
                                  </div>
                                </div>
                              </div>
                            </div></>
                        )}
                        <div className="my-3">
                          {messages.map((message, index) => (
                            <p key={index} className={`border rounded-3 p-3 mb-3 text-white ${message.type === 'sent' && message.message.includes('processed successfully') ? 'bg-primary' : message.type === 'sent' ? 'bg-success' : 'bg-secondary'}`}>{message.message}</p>
                          ))}
                        </div>
                        {loadingA && (
                          <div className="d-flex align-items-center">
                            <div className="spinner-border me-3" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        )}
                        <div className="my-3 d-flex">
                          <input type="text" className="form-control me-3" placeholder="Ask anything about the latest news.." value={inputValue} onChange={handleAskChanged} onKeyDown={handleKeyDown} />
                          <button className="btn btn-primary" onClick={handleAskChange}
                          >Send</button>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col> */}
                  {newsElements}
                </React.Fragment>
              ) :
                <div className={`d-flex card-header p-1 m-0 d-flex justify-content-between ${isSmallScreen ? 'pb-3' : 'pb-2'}`}>
                  <div className="d-flex header-title p-2 me-4">
                    <h4>Newsfeed</h4>{loading && (
                      <div className="spinner-border ms-4" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </div>{!isSmallScreen &&
                    <Dropdown>
                      <Dropdown.Toggle href="#" variant="d-flex align-items-center" >
                        <div className="caption d-none d-lg-block">
                          <h6 className="mb-0 line-height" style={{ fontSize: '12px' }}>Sort by: <Link>{selectedSort}</Link></h6>
                        </div>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Card className="shadow-none m-0">
                          <Card.Header>
                            <h6>Filters</h6>
                          </Card.Header>
                          <Card.Body className="p-0">
                            <div
                              className="d-flex align-items-center iq-sub-card border-0"
                              onClick={() => handleSortClick('Trending')}
                            >
                              <span className="material-symbols-outlined">task_alt</span>
                              <div className="ms-3">
                                <Link className="mb-0 h6">Trending</Link>
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center iq-sub-card border-0"
                              onClick={() => handleSortClick('Most Liked')}
                            >
                              <span className="material-symbols-outlined">task_alt</span>
                              <div className="ms-3">
                                <Link className="mb-0 h6">Most Liked</Link>
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center iq-sub-card border-0"
                              onClick={() => handleSortClick('Most Viewed')}
                            >
                              <span className="material-symbols-outlined">task_alt</span>
                              <div className="ms-3">
                                <Link className="mb-0 h6">Most Viewed</Link>
                              </div>
                            </div>

                          </Card.Body>
                        </Card>
                      </Dropdown.Menu>
                    </Dropdown>}

                </div>}
              {/* {Object.keys(data).length ? (
                <Col sm={12} key={999}>
                  <Card className="card-block card-stretch card-height ">
                    <Card.Body><div className='d-flex'> <Link><span style={{ fontSize: '18px' }} title="Click me to read more information about ScooptGPT!" class="material-symbols-outlined me-2" onClick={handleClickedInfo}>info</span></Link>
                      <div>ScooptGPT <Link style={{ fontSize: '12px' }}>beta</Link> </div></div>
                      {isClickedInfo && (
                        <><div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} style={showModal ? { display: 'block', pointerEvents: 'none' } : { pointerEvents: 'none' }}></div>
                          <div
                            className={`modal ${showModal ? 'show' : ''}`}
                            tabIndex="-1"
                            style={
                              showModal
                                ? { display: 'block', pointerEvents: 'none' }
                                : { pointerEvents: 'none' }}>
                            <div className="modal-dialog modal-dialog-centered ps-3">
                              <div className="modal-content">
                                <div className="modal-header">
                                  <h5 className="modal-title">ScooptGPT <Link style={{ fontSize: '12px' }}>beta</Link> </h5>
                                  <button
                                    type="button"
                                    className="btn-close"
                                    onClick={handleModalClose}
                                  ></button>
                                </div>
                                <div className="modal-body">
                                  Introducing ScooptGPT <Link style={{ fontSize: '12px' }} onClick={handleClickBeta}>beta</Link>, a refined model evolved from GPT-3.5-turbo, specifically trained on the past three days of news content.<br></br><br></br>

                                  ScooptGPT <Link style={{ fontSize: '12px' }}>beta</Link> is designed to address a wide range of inquiries; however, it will respond with 'I don't know' for questions outside the scope of news-related topics.<br></br><br></br>

                                  For subscribed users, Scoopt presents ScooptGPT <Link style={{ fontSize: '12px' }} onClick={handleClickTurbo}>turbo</Link>, an advanced model based on the cutting-edge GPT-4 architecture which has been trained on a wide variety of news.<br></br><br></br>

                                  Example prompts: <br></br><Link> 'Summarise the latest news in 100 words'</Link> <br></br><Link>'Ukraine war latest news'</Link> <br></br><Link>'Incoming recession news'</Link>
                                </div>
                              </div>
                            </div>
                          </div></>
                      )}
                      <div className="my-3">
                        {messages.map((message, index) => (
                          <p key={index} className={`border rounded-3 p-3 mb-3 text-white ${message.type === 'sent' && message.message.includes('processed successfully') ? 'bg-primary' : message.type === 'sent' ? 'bg-success' : 'bg-secondary'}`}>{message.message}</p>
                        ))}
                      </div>
                      {loadingA && (
                        <div className="d-flex align-items-center">
                          <div className="spinner-border me-3" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                      <div className="my-3 d-flex">
                        <input type="text" className="form-control me-3" placeholder="Ask anything about the latest news.." value={inputValue} onChange={handleAskChanged} onKeyDown={handleKeyDown} />
                        <button className="btn btn-primary" onClick={handleAskChange}
                        >Send</button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>) : null} */}
              {
                removeDuplicatesByTitle(
                  Object.entries(data)
                    .filter(([id, { Time }]) => {
                      const postDate = new Date(Time);
                      const currentDate = new Date();
                      return postDate < currentDate;
                    })
                ).map(([id, { site, siteImg, likes, views, Author, Tags, Time, Title, Body, Summary, Article, ArticleImg, Url, idf, Categories }]) => (
                  <Col sm={12} key={idf} onClick={() => handleColClick(idf)}>
                    <Card className="card-block card-stretch card-height ">

                      <Card.Body>

                        <div className="user-post-data ">
                          <div className="d-flex justify-content-between ">
                            <div className="me-3">
                              <img className="rounded-circle img-fluid" src={siteImg} alt="" />
                            </div>
                            <div className="w-100">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <h5 className="mb-0 d-inline-block">{site}</h5>
                                  <p className="mb-0 text-primary ">{Time.slice(0, -3)}</p>
                                </div>
                                <div className='ms-auto mt-2 '>
                                  {colStates[idf] ?
                                    <div><Link title="You have seen this post!" className='d-flex'><i className="icon material-symbols-outlined">
                                      visibility
                                    </i><div className='ms-1'>{colStates[idf] ? parseInt(views, 10) + 1 : views}</div></Link> </div> :
                                    <Link title="You have not seen this post!"><i className="icon material-symbols-outlined">
                                      visibility_off
                                    </i></Link>
                                  }
                                </div>
                                <div className="card-post-toolbar">
                                  <Dropdown>
                                    <Dropdown.Toggle variant="bg-transparent">
                                      <span className="material-symbols-outlined">
                                        more_horiz
                                      </span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                      <Dropdown.Item className=" p-3" to="#">
                                        <div className="d-flex align-items-top">
                                          <div className="h4 material-symbols-outlined">
                                            <i className="ri-save-line"></i>
                                          </div>
                                          <div className="data ms-2" onClick={() => savePost(Title, ArticleImg, Url, site, Summary)}>
                                            <h6>Save Post</h6>
                                            <p className="mb-0">Add this to your saved items</p>
                                          </div>
                                        </div>
                                      </Dropdown.Item>
                                      <Dropdown.Item className="p-3" to="#" onClick={() => handleHidePost(idf)}>
                                        <div className="d-flex align-items-top">
                                          <i className="ri-close-circle-line h4"></i>
                                          <div className="data ms-2">
                                            <h6>Hide Post</h6>
                                            <p className="mb-0">See fewer posts like this.</p>
                                          </div>
                                        </div>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </div>

                          {Url.startsWith('https://www.youtube.com/') ? (
                            <div className="gap-3">
                              <div className="mt-3">
                                <div className="user-post">
                                  <div className="mb-4 ratio ratio-16x9">
                                    <iframe title="video" src={Url} ></iframe>
                                  </div>
                                </div>
                                <div>
                                  <h4 className="mb-3 ">{Title}</h4>
                                  {idf === currentArticleId ? (
                                    <div>
                                      <h5>Summary</h5>
                                      <Link onClick={handleReset}>x</Link>
                                      <br></br>
                                      <p>{Summary}</p>
                                    </div>
                                  ) : (
                                    <div>
                                      <p>
                                        {showFullArticle[idf] ? (
                                          <div>
                                            <h5>Article</h5>
                                            <br></br>
                                            <p>{Article}</p>
                                          </div>
                                        ) : (
                                          <div>
                                            {Article.length > 0 ? (
                                              <p>
                                                {Article.length > 130
                                                  ? `${Article.substr(0, 130)}...`
                                                  : Article}
                                              </p>
                                            ) : (
                                              <div>
                                                <p>
                                                  {Summary.length > 130
                                                    ? `${Summary.substr(0, 130)}...`
                                                    : Summary}
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </p>
                                    </div>
                                  )}
                                  Reading time: <Link>{displayArticleWithReadingTime(Article)} minutes</Link>
                                  Categories: <Link>Technology</Link>, <Link>Finance</Link>, <Link>Politics</Link>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className={`align-items-center mb-2 mt-3 ${isSmallScreen ? '' : 'd-flex'}`} >
                              <img src={ArticleImg} alt="post3" className="rounded me-4" style={{ objectFit: "cover", width: isSmallScreen ? "300px" : "370px", height: isSmallScreen ? "200px" : "210px", marginBottom: isSmallScreen ? "2vh" : null }} />

                              <div>
                                <div
                                  className='d-inline-block w-100'
                                  style={{
                                    fontSize: '14px',
                                    color: hovered ? 'var(--bs-primary)' : 'inherit',
                                    cursor: 'pointer',
                                  }}
                                  onMouseEnter={onHoverIn}
                                  onMouseLeave={onHoverOut}
                                >
                                  <h4 className='mb-3'>{Title}</h4>
                                </div>
                                {idf === currentArticleId ? (
                                  <div>
                                    <div className='d-flex'>
                                      <h5>Summary</h5>
                                      
                                      <Link>
                                        <i onClick={handleReset} className=" ms-2 icon material-symbols-outlined" style={{ cursor: 'pointer' }}>
                                          highlight_off
                                        </i></Link>
                                    </div>
                                    <br></br>
                                    {Summary.split('.').map((paragraph, index) => (
                                      <p key={index}>{paragraph}.</p>
                                    ))}
                                  </div>
                                ) : (
                                  <div>
                                    <p>
                                      {showFullArticle[idf] ? (
                                        <div>
                                          <div className='d-flex'>
                                            <h5>Article</h5><Link><i onClick={handleReset} className="ms-2 icon material-symbols-outlined" style={{ cursor: 'pointer' }}>
                                              highlight_off
                                            </i></Link>
                                          </div>
                                          <br></br>
                                          {Article.split('.').map((paragraph, index) => (
                                            <p key={index}>{paragraph}.</p>
                                          ))}
                                        </div>
                                      ) : (
                                        <div>
                                          <p>
                                            {Summary.length > 50 ? <div>
                                              {expandedBody[Title] ? <div><div className='mb-3 mt-3'>Summary </div>{Summary}<Link className='me-4' onClick={() => handleSeeLessClick()}>
                                                {' '}
                                                Close Summary...{' '}
                                              </Link> </div> : Summary.substr(0, 130)}
                                              {Summary.length > 130 && !expandedBody[Title] && (
                                                <Link className='me-4' onClick={() => handleSeeMoreClick(Title)}>
                                                  {' '}
                                                  ...see more{' '}
                                                </Link>
                                              )}</div> : <div>
                                              {expandedBody[Title] ? <div><div className='mb-3 mt-3'>Summary:</div>{Article}<Link className='me-4' onClick={() => handleSeeLessClick()}>
                                                {' '}
                                                See less...{' '}
                                              </Link> </div> : Article.substr(0, 130)}
                                              {Article.length > 130 && !expandedBody[Title] && (
                                                <Link className='me-4' onClick={() => handleSeeMoreClick(Title)}>
                                                  {' '}
                                                  ...see more{' '}
                                                </Link>
                                              )}</div>}

                                          </p>
                                        </div>
                                      )}
                                    </p>
                                  </div>
                                )}
                                <div >
                                  <div onClick={() => setShowFullArticle(prevState => ({ ...prevState, [idf]: !prevState[idf] }))}>Reading time: <Link>~ {displayArticleWithReadingTime(Article)} minute(s)</Link></div>

                                  {Categories && Categories.length > 0 && (
                                    <div>
                                      Categories:&nbsp;
                                      {Categories.filter(category => category !== "Entertainment").map((category, index) => (
                                        <React.Fragment key={index}>
                                          <Link>{category}</Link>
                                          {index !== Categories.filter(category => category !== "Entertainment").length - 1 && ", "}
                                        </React.Fragment>
                                      ))}
                                      {Categories.filter(category => category !== "Entertainment").length > 0 && Categories.filter(category => category !== "Entertainment")[Categories.filter(category => category !== "Entertainment").length - 1].endsWith(",") ? Categories.filter(category => category !== "Entertainment")[Categories.filter(category => category !== "Entertainment").length - 1].slice(0, -1) : null}
                                    </div>
                                  )}
                                  {/* {Tags && Tags.length > 0 && (
                              <div>
                                Tags:&nbsp;
                                {Tags.map((tag, index) => (
                                  <React.Fragment key={index}>
                                      <Link>{tag.charAt(0).toUpperCase() + tag.slice(1)}</Link>
                                      {index !== Tags.length - 1 && (", ")}
                                  </React.Fragment>
                                ))}
                              </div>
                            )} */}
                                  <br></br>
                                  <div className='d-flex'>
                                  <div className="d-flex  flex-wrap">
                                    <div className="like-block position-relative d-flex align-items-center">
                                      <div className="d-flex align-items-center">
                                        <div key={idf}>
                                          <div className="d-flex like-data">
                                            <img
                                              src={likess[idf] ? icon2 : icon1}
                                              className="img-fluid"
                                              alt=""
                                              onClick={() => handleLikeClick(idf, likes)}
                                            />
                                            <div className='ms-2'>
                                              {likess[idf] ? parseInt(likes, 10) + 1 : likes}</div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={`total-comment-block ${isSmallScreen ? 'ms-3' : 'ms-4'}`} onClick={() => handleClick(idf)} style={{ fontWeight: activeDiv === idf ? 'bold' : 'normal' }}>
                                        <div className='d-flex'><span className="material-symbols-outlined" style={{ marginTop: '2px' }}>speaker_notes</span><div className='ms-2'>{comments.length}</div></div>
                                      </div>
                                      {/* <Link to="#" className="d-flex align-items-center">
                                        <div className="total-like-block ms-4 me-3" onClick={() => { handleReset(); setCurrentArticleId(currentArticleId === idf ? null : idf) }} style={{ fontWeight: currentArticleId === idf ? 'bold' : 'normal' }}>
                                          Summary
                                        </div>
                                      </Link>
                                      <Link to="#" className="d-flex align-items-center">
                                        <div
                                          className="total-like-block ms-2 me-3"
                                          style={{ fontWeight: showFullArticle[idf] ? 'bold' : 'normal' }}
                                          onClick={() => { handleReset(); setShowFullArticle(prevState => ({ ...prevState, [idf]: !prevState[idf] })) }}
                                        >
                                          Article
                                        </div>

                                      </Link> */}
                                    </div>
                                    <Link to="#" className="d-flex"><span onClick={() => window.open(Url)} class=" ms-4 me-2 material-symbols-outlined">
                                      open_in_new
                                    </span>
                                      <div className="total-like-block" style={{ fontSize: isSmallScreen ? '12px' : '14px' }} onClick={() => window.open(Url)}>
                                        Open on {site}
                                      </div>
                                    </Link>
                                    
                                  </div><div className='ms-auto'><ShareOffcanvas idf={idf} Url={Url} /></div></div>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="comment-area">

                            {activeDiv === idf && (
                              <ul className="post-comments list-inline p-0 m-0">
                                {/* Check if comments is defined and has a length greater than 0 */}
                                {comments && comments.length > 0 ? (
                                  comments.map((comment) => (
                                    <div>
                                      <hr />
                                      <li className="mb-2">
                                        <div className="d-flex">
                                          <div className="user-img">
                                            <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid" />
                                          </div>
                                          <div className="comment-data-block ms-3">
                                            <h6>
                                              {comment.username && comment.username.includes(':') || comment.username.includes('.') ? <div><Link>Guest</Link></div> : comment.username}
                                            </h6>
                                            <p className="mb-0">{comment.comment}</p>
                                            <div className="d-flex flex-wrap align-items-center comment-activity">
                                              <Link to="#">like</Link>
                                              <Link to="#">reply</Link>
                                              <Link to="#">translate</Link>
                                              <span>{comment.time && comment.time.slice(0, -7)}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </li></div>

                                  ))
                                ) : (
                                  <div>
                                    <hr />
                                    <li className="mb-2">No comments yet.</li></div>
                                )}
                                <input
                                  type="text"
                                  className="form-control rounded"
                                  placeholder="Enter Your Comment"
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      addComment(event.target.value, idf);
                                      event.target.value = "";
                                      // sendComment(event.target.value, id); Call the sendComment function with the comment, userId, and postId

                                    }
                                  }}
                                />
                              </ul>

                            )}


                          </div>

                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Col>

          </Row>
        </Container>
        <div id="end-of-page" style={{ height: '1px' }}></div>
      </div>
    </div>
  );
};

export default Index;
