import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import Header from '../../../components/partials/dashboard/HeaderStyle/header'


//css
import './email-compose.css'


const EmailCompose = () => {
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [showThankYouMessage, setShowThankYouMessage] = useState(false);
    const [accessToken, setAccessToken] = useState(!localStorage.getItem("access_token") ? "" : localStorage.getItem("access_token"))

    useEffect(() => {
        if (localStorage.getItem("access_token") == null) {
            fetch("https://scoopt.ngrok.app/jwt_login", {
                method: "POST",
                credentials: 'include',
                headers: {
                    "ngrok-skip-browser-warning": "69420",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ "username": "test", "password": "password" }),
            }).then(response => response.json()).then(data => {
                localStorage.setItem("access_token", data)
                setAccessToken(data)
            })
        }
    }, []);

    const handleSend = async () => {
        const response = await fetch('https://scoopt.ngrok.app/feedback', {
            method: 'POST',
            credentials: 'include',
            headers: new Headers({
                "ngrok-skip-browser-warning": "69420",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }),
            body: JSON.stringify({ subject, message })
        });

        if (!response.ok) {
            throw new Error(`Failed to submit feedback: ${response.status}`);
        }

        setShowThankYouMessage(true);
    };
    return (
        <>
            <Header />
            <div style={{ marginTop: '5vh' }}>
                <div id='content-page' className='content-page'>
                    <Container className='relative'>
                        <Row className="row-eq-height">
                            <Col md="12">
                                <Row>
                                    <Col md="12">
                                        <Card className="iq-border-radius-20">
                                            <Card.Body>
                                                <Row>
                                                    <Col md="12" className="mb-3">
                                                        <h5 className="text-primary card-title"><i className="ri-pencil-fill"></i> Help us improve by leaving some feedback!</h5>
                                                    </Col>
                                                </Row>
                                                <Form className="email-form">
                                                    <Row className="form-group">
                                                        <Form.Label column sm="2" htmlFor="subject" className="col-form-label">Subject:</Form.Label>
                                                        <Col sm="10">
                                                            <Form.Control type="text" id="subject" value={subject} onChange={(event) => setSubject(event.target.value)} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="form-group">
                                                        <Form.Label htmlFor="subject" column sm="2" className="col-form-label">Your Message:</Form.Label>
                                                        <Col md="10">
                                                            <Form.Control as="textarea" className="textarea" rows={5} placeholder="Feedback.." value={message} onChange={(event) => setMessage(event.target.value)} />
                                                        </Col>
                                                    </Row>
                                                    <Form.Group className="form-group d-flex justify-content-between align-items-center compose-bottom pt-3 m-0">
                                                        <div className="d-flex flex-wrap flex-grow-1 align-items-center">
                                                            <div className="send-btn pl-3 mb-2">
                                                                <button type="button" className="btn btn-primary" onClick={handleSend}>Send</button>
                                                            </div>
                                                            <div className="send-panel mb-2">
                                                                <Form.Label className="ms-2 mb-0 bg-soft-primary rounded" htmlFor="file"> <Form.Control type="file" id="file" style={{ display: "none" }} /><Link to="#" className="material-symbols-outlined writ-icon text-primary">attachment</Link> </Form.Label>
                                                                <Form.Label className="ms-2 mb-0 bg-soft-primary rounded"> <Link to="#" className="material-symbols-outlined text-primary">sentiment_satisfied</Link> </Form.Label>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex mr-3 align-items-center">
                                                            <div className="send-panel float-right">
                                                                <Form.Label className="ms-2 mb-0 bg-soft-primary rounded" ><Link to="#" className="material-symbols-outlined text-primary">settings</Link></Form.Label>
                                                                <Form.Label className="ms-2 mb-0 bg-soft-primary rounded"><Link to="#" className="material-symbols-outlined">delete</Link>  </Form.Label>
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </Form>
                                                {showThankYouMessage && (
                                                    <div className="alert alert-success mt-3" role="alert">
                                                        Thank you for your feedback!
                                                    </div>
                                                )}
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    )
}

export default EmailCompose