import React from 'react'
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap'
import Header from '../../../components/partials/dashboard/HeaderStyle/header'

const UserAccountSetting = () => {
    return (
        <>
            <Header />

            <div style={{ marginTop: '20vh', marginBottom: '40vh' }}>
                <Container>
                    <Row>
                        <Col lg="6">
                            <Card>
                                <Card.Header className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Account Settings</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="acc-edit">
                                        <Form>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="uname" className="form-label">User Name:</Form.Label>
                                                <Form.Control type="text" className="form-control" id="uname" defaultValue="Guest" />
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label htmlFor="email" className="form-label">Email Id:</Form.Label>
                                                <Form.Control type="email" className="form-control" id="email" defaultValue="guest@scoopt.io" />
                                            </Form.Group>
                                            <Button type="submit" className="btn btn-primary me-2">Submit</Button>
                                            <Button type="reset" className="btn bg-secondary">Cancel</Button>
                                            <Button type="reset" className="ms-3 btn bg-danger" onClick={() => alert("Your account will be deleted within the next 24 hours.")}>Delete Account</Button>
                                        </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                    </Row>
                </Container>
            </div>
        </>
    )

}

export default UserAccountSetting;