import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const ShareOffcanvas = ({ idf, Url }) => {
  const [urlCopied, setUrlCopied] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 450);
  const handleWindowResize = () => {
    setIsSmallScreen(window.innerWidth < 450);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function handleCopyToClipboard() {
    const formattedText = Url;
    navigator.clipboard.writeText(formattedText);
    setUrlCopied(true);
    setTimeout(() => {
      setUrlCopied(false);
    }, 1000);
  }
  

  return (
    <>
      <div className="d-flex align-items-center feather-icon  mt-md-0">
        <Link to="#" onClick={handleCopyToClipboard} className="d-flex align-items-center">
          <span className="material-symbols-outlined md-18">share</span>
          {isSmallScreen ? null : <span className="ms-1">Share</span>}
        </Link>
      </div>
      {urlCopied && <p className='mt-4 mx-auto' style={{fontStyle: 'italic', fontSize: '11px'}}>Copied URL for post <Link>{idf}</Link></p>}
    </>
  );
};

export default ShareOffcanvas;
