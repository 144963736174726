import React from 'react'
import { Row, Col, Container, Dropdown } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import Header from '../../../components/partials/dashboard/HeaderStyle/header'

import user1 from '../../../assets/images/user/01.jpg'

const Notification = () => {
   return (
      <>
         <Header />
         <div id='content-page' className='content-page'>
            <Container>
               <Row>
                  <Col sm="12">
                     <h4 className="card-title mb-3">Notifications</h4>
                  </Col>
                  <Col sm="12">
                     <Card>
                        <Card.Body>
                           <ul className="notification-list m-0 p-0">
                              <li className="d-flex align-items-center justify-content-between">
                                 <div className="user-img img-fluid">
                                    <img src={user1} alt="story-img" className="rounded-circle avatar-40" />
                                 </div>
                                 <div className="w-100">
                                    <div className="d-flex justify-content-between">
                                       <div className=" ms-3">
                                          <h6>No notifications yet</h6>
                                          <p className="mb-0">2023-03-14 13:25</p>
                                       </div>
                                       <div className="d-flex align-items-center">
                                          <div className="card-header-toolbar d-flex align-items-center">
                                             <Dropdown>
                                                <Link to="#">
                                                   <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                      more_horiz
                                                   </Dropdown.Toggle>
                                                </Link>
                                                <Dropdown.Menu className="dropdown-menu-right">
                                                   <Dropdown.Item to="#">
                                                      <i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                   <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                </Dropdown.Menu>
                                             </Dropdown>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </Card.Body>
                     </Card>
                  </Col>
               </Row>
            </Container>
         </div>
      </>
   )
}

export default Notification