import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button } from 'react-bootstrap'
import Header  from '../../../components/partials/dashboard/HeaderStyle/header'
import { useStripe } from "@stripe/react-stripe-js";
const Pricing = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [logged, setLogged] = useState(false)
    const stripe = useStripe();
    const [accessToken, setAccessToken] = useState(!localStorage.getItem("access_token") ? "" : localStorage.getItem("access_token"))
  
    useEffect(() => {
      if (localStorage.getItem("access_token") == null) {
        fetch("https://scoopt.ngrok.app/jwt_login", {
          method: "POST",
          credentials: 'include',
          headers: {
            "ngrok-skip-browser-warning": "69420",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ "username": "test", "password": "password" }),
        }).then(response => response.json()).then(data => {
          localStorage.setItem("access_token", data)
          setAccessToken(data)
        })
      }
    }, []);
    const handleConfirm = async (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    
        if (index === 1) {
          const response = await fetch("https://scoopt.ngrok.app/checkout", {
            method: "POST",
            credentials: "include",
            headers: new Headers({
                "ngrok-skip-browser-warning": "69420",
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
              }),
            body: JSON.stringify({
              // Pass any necessary data to your server to create a Checkout Session
            }),
          });
          const session = await response.json();
          const result = await stripe.redirectToCheckout({
            sessionId: session.id,
          });
    
          if (result.error) {
            console.error(result.error.message);
          }
        }
      };

    return (
        <>
            <Header />
            <div id='content-page' className='content-page'>
                <Container>
                    <Row>
                        <Col sm="12">
                            <Card className="position-relative inner-page-bg bg-primary" style={{ height: "100px" }}>
                                <div className="inner-page-title">
                                    <h3 className="text-white">Upgrade Plan</h3>
                                </div>
                            </Card>
                        </Col>
                        <Col sm="12">
                            <Card>
                                <Card.Body>
                                    <div className="table-responsive">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" scope="col"></th>
                                                    <th className="text-center" scope="col">Starter</th>
                                                    <th className="text-center" scope="col">Premium</th>
                                                    <th className="text-center" scope="col">Unlimited</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th className="text-center" scope="row">Suggested News</th>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center" scope="row">History</th>
                                                    <td className="text-center">
                                                        3 days
                                                    </td>
                                                    <td className="text-center">
                                                        3 weeks
                                                    </td>
                                                    <td className="text-center">
                                                        Unlimited
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center" scope="row">Scroll</th>
                                                    <td className="text-center">
                                                        3k posts / month
                                                    </td>
                                                    <td className="text-center">
                                                        30k posts / month
                                                    </td>
                                                    <td className="text-center">
                                                        Unlimited
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center" scope="row">Saves</th>
                                                    <td className="text-center">
                                                        30 saves / month
                                                    </td>
                                                    <td className="text-center">
                                                        300 saves / month
                                                    </td>
                                                    <td className="text-center">
                                                        Unlimited
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center" scope="row">Personalized News</th>
                                                    <td className="text-center">-
                                                    </td>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center" scope="row">Personalized Notifications</th>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center" scope="row">Request Sources</th>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center" scope="row">Request Customizations</th>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">-</td>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="text-center" scope="row">Unblured Images</th>
                                                    <td className="text-center">-
                                                    </td>
                                                    <td className="text-center">-
                                                    </td>
                                                    <td className="text-center">
                                                        <i className="material-symbols-outlined text-success md-32">check</i>
                                                    </td>
                                                </tr>
                                                <br></br>
                                                <tr>
                                                    <td className="text-center"></td>
                                                    <td className="text-center">
                                                        <h2 className="text-center mb-2" style={{ fontSize: '18px' }}>Free</h2>
                                                        <Button
                                                            variant={activeIndex === 0 ? 'success' : 'primary'}
                                                            className="mt-3"
                                                            onClick={() => handleConfirm(0)}
                                                        >
                                                            {activeIndex === 0 ? 'Confirmed' : 'Confirm'}
                                                        </Button>
                                                    </td>
                                                    <td className="text-center">
                                                        <h2 className="text-center mb-2" style={{ fontSize: '18px' }}>£0.99<small style={{ fontStyle: 'italic' }}> / Month</small></h2>
                                                        {localStorage.getItem('logged') == "true"? <Button
                                                            variant={activeIndex === 1 ? 'success' : 'primary'}
                                                            className="mt-3"
                                                            onClick={() => handleConfirm(1)}
                                                        >
                                                            {activeIndex === 1 ? 'Confirmed' : 'Confirm'}
                                                        </Button>: <Button className="mt-3 "> Log in & pay </Button> /* TODO */} 
                                                    </td>
                                                    <td className="text-center">
                                                        <h2 className="text-center mb-2" style={{ fontSize: '18px' }}>£4.99<small style={{ fontStyle: 'italic' }}> / Month</small></h2>
                                                        {/* <Button
                                                            variant={activeIndex === 2 ? 'success' : 'primary'}
                                                            className="mt-3"
                                                            onClick={() => handleConfirm(2)}
                                                            disabled={activeIndex !== -1 && activeIndex !== 2}
                                                        >
                                                            {activeIndex === 2 ? 'Confirmed' : 'Confirm'}
                                                        </Button> */}
                                                        <div className="mt-4" style={{fontStyle: 'italic'}}>Coming Soon</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Pricing
