import { Container, Row, Col } from 'react-bootstrap'
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import Header from '../../../components/partials/dashboard/HeaderStyle/header'
const BlankPage = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [trainingMessageIndex, setTrainingMessageIndex] = useState(0);
  const [numUploadedFiles, setNumUploadedFiles] = useState(0);
  const [countdownTime, setCountdownTime] = useState(0);
  const messageInputRef = useRef();

  const handleClick = () => {
    console.log(messageInputRef.current.value);
    sendMessage(messageInputRef.current.value);
    messageInputRef.current.value = '';
  };
  const trainingMessages = ['Uploading Document...', 'Processing Document...', 'Loading GPT-4...', 'Training Document...', 'Attaching Document to Chat...', 'Finalising...'];

  const onDrop = useCallback(acceptedFiles => {
    setLoading(true);
    setNumUploadedFiles(acceptedFiles.length);
    // Send file to API
    const formData = new FormData();
    acceptedFiles.forEach(file => {
      formData.append('file', file);
    });

    fetch('https://scoopt.ngrok.app/upload', {
      method: 'POST',
      credentials: 'include',
      body: formData
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setMessages([...messages, { message: `File(s) processed successfully.`, type: 'sent' }]);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [messages]);

  const sendMessage = useCallback(message => {
    setLoading(true);
    fetch('https://scoopt.ngrok.app/chat', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ message })
    })
      .then(response => response.json())
      .then(data => {
        setMessages([...messages, { message, type: 'sent' }, { message: data.message, type: 'received' }]);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
      });
  }, [messages]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    let intervalTime = Math.floor(Math.random() * 11) + 25;
    const interval = setInterval(() => {
      if (intervalTime <= 0) {
        intervalTime = Math.floor(Math.random() * 11) + 25;
        setTrainingMessageIndex((trainingMessageIndex + 1) % trainingMessages.length);
      } else {
        intervalTime -= 1;
        setCountdownTime(intervalTime);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [trainingMessageIndex, trainingMessages.length]);
  return (
    <>
      <Header />
      <div id='content-page' className='content-page'>

        <Container>
          <Row>
            <Col lg="12">
              <div className="container my-3">
                <div {...getRootProps()} className="border border-2 border-dashed p-5 text-center cursor-pointer">
                  <input {...getInputProps()} />
                  {
                    isDragActive ?
                      <p className="display-1">+</p> :
                      <p className="lead"> Drag &amp; drop .pdf files here, or click to select files</p>
                  }
                  <button className="btn btn-primary my-3">Upload file</button>
                </div>
                <div className="my-3">
                  {messages.map((message, index) => (
                    <p key={index} className={`border rounded-3 p-3 mb-3 text-white ${message.type === 'sent' && message.message.includes('processed successfully') ? 'bg-primary' : message.type === 'sent' ? 'bg-success' : 'bg-secondary'}`}>{message.message}</p>
                  ))}
                </div>
                {loading && (
                  <div className="d-flex align-items-center">
                    <div className="spinner-border me-3" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div>{trainingMessages[trainingMessageIndex]} {countdownTime > 0 && `(${countdownTime})`}</div>
                    {numUploadedFiles > 0 && (
                      <div className="ms-auto">
                        {numUploadedFiles} file(s) uploaded
                      </div>
                    )}
                  </div>
                )}
                <div className="my-3 d-flex">
                  <input type="text" className="form-control me-3" placeholder="Ask something about your documents.." ref={messageInputRef} />
                  <button className="btn btn-primary" onClick={handleClick}
                  >Send</button>
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default BlankPage