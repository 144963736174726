import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap'
import Card from '../../../components/Card'
import { Link } from 'react-router-dom'
import Header from '../../../components/partials/dashboard/HeaderStyle/header'

const File = () => {
    const [savedFiles, setSavedFiles] = useState([]);
    const [data, setData] = useState([]);
    const [expandedBody, setExpandedBody] = useState({});
    const duplicates = new Set()
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
    const [accessToken, setAccessToken] = useState(!localStorage.getItem("access_token")? "": localStorage.getItem("access_token"))

    useEffect(() => {
      if (localStorage.getItem("access_token") == null) {
        fetch("https://scoopt.ngrok.app/jwt_login", {
        method: "POST",
        credentials: 'include',
        headers: {
          "ngrok-skip-browser-warning": "69420",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ "username": "test", "password": "password" }),
      }).then(response => response.json()).then(data => {
        localStorage.setItem("access_token", data)
        setAccessToken(data)
      })
    }
    }, []);

    const handleWindowResize = () => {
        setIsSmallScreen(window.innerWidth < 1000);
    };
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const handleSeeLessClick = () => {
        setExpandedBody({});
    }
    const handleSeeMoreClick = (title) => {
        setExpandedBody({ ...expandedBody, [title]: true });
    };
    useEffect(() => {
        if(accessToken.length == 0)
            return

        const fetchData = async () => {
            const response = await fetch('https://scoopt.ngrok.app/get_saved', {
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${accessToken}`
                  }),
                credentials: 'include'
            })
            const data = await response.json();
            console.log(data)
            setData(data);
        };
        fetchData();
    }, [accessToken]);
    return (
        <>
            <Header />
            <div id='content-page' className='content-page'>
                <Container >
                    <Row className='justify-content-center' >
                        <Col lg='6' className='w-100'>
                            <Card>
                                <Card.Header className='d-flex justify-content-between'>
                                    <div className='header-title'>
                                        <h4 className='card-title'>Saved</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {data && (
                                        <ul className='iq-timeline ms-1'>
                                            {Object.entries(data)
                                                .filter(([id, item]) => item.filename && !duplicates.has(item.filename) && duplicates.add(item.filename)) // filter duplicates by filename
                                                .sort(([, a], [, b]) => new Date(b.date) - new Date(a.date)).map(([id, { date, filename, Summary, img, site, url }]) => (
                                                    date && (
                                                        <li key={id}>
                                                            <div className='timeline-dots'></div>
                                                            <div className={`${isSmallScreen ? '' : 'd-flex'}`}>
                                                                <div className='d-flex'><Link title="You have seen this post!"><i className="icon material-symbols-outlined me-3">
                                                                    bookmark_added
                                                                </i></Link> 
                                                                <h6 className='mb-1'>Saved a post from <Link onClick={() => window.open(url)}>{site}</Link></h6>
                                                                </div>
                                                                <small className='ms-auto'><Link>{date ? date.slice(0, -7) : ''}</Link></small>
                                                            </div>
                                                            <br></br>
                                                            <div className={`${isSmallScreen ? '' : 'd-flex'}`}>
                                                                <img
                                                                    src={img}
                                                                    className={`me-4 ${isSmallScreen ? 'mb-3' : ''}`}
                                                                    style={{ maxWidth: '200px', maxHeight: '200px', filter: "blur(3px)" }}
                                                                />
                                                                <div>
                                                                    <div
                                                                        className='d-inline-block w-100'
                                                                        style={{
                                                                            fontSize: '14px',
                                                                            color: 'inherit',
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        // onMouseEnter={onHoverIn}
                                                                        // onMouseLeave={onHoverOut}
                                                                        onClick={() => window.open(url)}
                                                                    >
                                                                        <p>{filename}</p>
                                                                    </div>
                                                                    {Summary ?
                                                                        <div className='d-inline-block w-100' style={{ fontSize: '12px' }}>
                                                                            {expandedBody[Summary] ? <div><div className='mb-3 mt-3'>Summary:</div>{Summary}<Link className='me-4' onClick={() => handleSeeLessClick()}>
                                                                                {' '}
                                                                                See less...{' '}
                                                                            </Link> </div> : Summary.substr(0, 200)}
                                                                            {Summary.length > 200 && !expandedBody[Summary] && (
                                                                                <Link className='me-4' onClick={() => handleSeeMoreClick(Summary)}>
                                                                                    {' '}
                                                                                    See more...{' '}
                                                                                </Link>
                                                                            )}
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            <hr />
                                                        </li>)
                                                ))}
                                        </ul>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
export default File


