import React, { useState, useEffect } from 'react';
import { Card, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import {
  Dropdown
} from "react-bootstrap";
import { handleClicky } from './verticalnav';
const RightSidebar = ({ setUpdate }) => {
  const [news, setNews] = useState([]);
  const [selectedSort, setSelectedSort] = useState('Global');
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [accessToken, setAccessToken] = useState(localStorage.getItem('access_token') == null ? "" : localStorage.getItem('access_token'))
  const [arrowDirection, setArrowDirection] = useState('arrow_back_ios');
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);
  const [isRelativelySmallScreen, setIsRelativelySmallScreen] = useState(window.innerWidth < 1525);
  const handleWindowResize = () => {
    setIsSmallScreen(window.innerWidth < 800);
  };
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [isSmallScreen]);
  const toggleArrow = () => {
    setArrowDirection(prevDirection => {
      return prevDirection === 'arrow_forward_ios' ? 'arrow_back_ios' : 'arrow_forward_ios';
    });
  };
  useEffect(() => {
    var accessTokenTimeout = setTimeout(() => {
      const token = localStorage.getItem("access_token");
      if (token != null) {
        setAccessToken(token);
        clearTimeout(accessTokenTimeout);
      }
    }, 100);

  })


  const minirightsidebar = () => {
    toggleArrow()
    document.getElementById('rightSidebar').classList.toggle('right-sidebar');
    document.body.classList.toggle('right-sidebar-close');
  };
  const handleSortClick = (category) => {
    setSelectedSort(category);
  };
  useEffect(() => {
    if (!isRelativelySmallScreen) {
      const timer = setTimeout(() => {
        minirightsidebar();
      }, 1000);
      return () => clearTimeout(timer);
  }
    
  }, []);

  const handleClick = (id, name, status) => {
    const newStatus = status === 'Inactive' ? 'Active' : 'Inactive';
    const updatedNews = news.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          status: newStatus,
        };
      }
      return item;
    });

    setNews(updatedNews);

    fetch('https://scoopt.ngrok.app/post_status', {
      method: 'POST',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      body: JSON.stringify({
        id,
        status: newStatus
      }),
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setUpdate(prevState => !prevState);
        handleClicky()
      })
      .catch(error => console.error(error));
  };

  const handleFavClick = (id) => {
    setSelectedItemId(id);

    fetch('https://scoopt.ngrok.app/post_fav', {
      method: 'POST',
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      body: JSON.stringify({
        id
      }),
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setUpdate(prevState => !prevState);
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    if (accessToken.length == 0)
      return;

    fetch('https://scoopt.ngrok.app/get_sources', {
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }),
      credentials: 'include'
    })
      .then((response) => response.json())
      .then((data) => {
        setNews(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [accessToken, setUpdate]);

  const sortedNews = news.sort((a, b) => {
    if (a.fav === 'star_half' && b.fav !== 'star_half') {
      return -1;
    } else if (a.fav !== 'star_half' && b.fav === 'star_half') {
      return 1;
    } else {
      return 0;
    }
  });

  const toggleFav = (id) => {
    const updatedNews = news.map((item) => {
      if (item.id === id) {
        item.fav = item.fav === 'star' ? 'star_half' : 'star';
      }
      return item;
    });

    setNews(updatedNews);
  };
  return (
    <>
      <div className="right-sidebar-mini"  id="rightSidebar">
        <div className="right-sidebar-panel p-0 border border-primary">
          <Card className="shadow-none ">
            <br></br>
            <div className='card-header ms-0 mb-0 p-0 '>
              <Dropdown>
                <Dropdown.Toggle href="#" variant="d-flex align-items-center" >
                  <div className="caption ">
                    <h4 className="mb-0 line-height" style={{ fontSize: '15px' }}>Select Source(s): <Link>{selectedSort}</Link></h4>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Card className="shadow-none m-0">
                    <Card.Body className="p-0">
                      <div
                        className="d-flex align-items-center iq-sub-card border-0"
                        onClick={() => handleSortClick('Global')}
                      >
                        <span className="material-symbols-outlined">task_alt</span>
                        <div className="ms-3">
                          <Link className="mb-0 h6">Global</Link>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center iq-sub-card border-0"
                        onClick={() => handleSortClick('UK & US')}
                      >
                        <span className="material-symbols-outlined">task_alt</span>
                        <div className="ms-3">
                          <Link className="mb-0 h6">UK & US</Link>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center iq-sub-card border-0"
                        onClick={() => handleSortClick('Greece')}
                      >
                        <span className="material-symbols-outlined">task_alt</span>
                        <div className="ms-3">
                          <Link className="mb-0 h6">Greece</Link>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Card.Body className="p-0" style={{ overflowY: 'scroll', height: '80vh', scrollbarWidth: 'thin', scrollbarColor: 'transparent transparent #C7C7CC transparent' }}>
              {sortedNews.filter((item) => (
                selectedSort === 'Global' ||
                (
                  selectedSort === 'Greece' ?
                    ["ERT News", "Kathimerini", "Sport24", "Contra", "To Bhma"].includes(item.name) :
                    !["ERT News", "Kathimerini", "Sport24", "Contra", "To Bhma"].includes(item.name)
                )
              )).map((item) => (

                <div
                  key={item.id}
                  className="media-height p-3"
                  data-scrollbar="init"
                  onClick={() => { handleClick(item.id, item.name, item.status) }}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={(e) => e.target.classList.add('border')}
                  onMouseLeave={(e) => e.target.classList.remove('border')}
                >

                  <div className="d-flex align-items-center justify-content-between" onMouseLeave={(e) => e.target.classList.remove('border')}>
                    <div className={item.status === "Active" ? "iq-profile-avatar status-online" : "iq-profile-avatar status-away"} onMouseLeave={(e) => e.target.classList.remove('border')}>
                      <Image
                        className="rounded-circle avatar-50"
                        src={item.imageUrl}
                        alt=""
                        loading="lazy"
                        onMouseLeave={(e) => e.target.classList.remove('border')}
                      />
                    </div>
                    <div className="ms-3 flex-grow-1" onMouseLeave={(e) => e.target.classList.remove('border')}>
                      <h6 className="mb-0" onMouseLeave={(e) => e.target.classList.remove('border')}>{item.name}</h6>
                      <Link> <p style={{ fontStyle: 'italic' }} className="mb-0" onMouseLeave={(e) => e.target.classList.remove('border')}>{item.status}</p></Link>
                    </div>
                    <div className='ml-auto' onMouseLeave={(e) => e.target.classList.remove('border')}>

                      <span className="material-symbols-outlined md-18" onMouseLeave={(e) => e.target.classList.remove('border')} onClick={(e) => {
                        e.stopPropagation();
                        toggleFav(item.id);
                        handleFavClick(item.id);
                      }}>
                        {item.fav}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
              <div className="right-sidebar-toggle bg-primary text-white d-flex" style={{ marginTop: isSmallScreen ? '5px' : '18px' }} onClick={minirightsidebar}>
                <span className="material-symbols-outlined" style={{ marginLeft: arrowDirection === 'arrow_back_ios' ? '5px' : '0px' }}>{arrowDirection}</span>
              </div>
              <style>
                {`
      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #C7C7CC;
        border-radius: 6px;
      }
    `}
              </style>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default RightSidebar;
